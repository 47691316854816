<div *ngIf="!deviceIsMobile" class="custom-field-web" id="custom-tag-field" [class.hide-fields]="!isEdited && isEmpty('tags')">
  <app-select-multiple-tags [parentFormGroup]="parentFormGroup" controlName="tags" [items]="tags"
                [isReadOnly]="!isEdited" [isLoading]="!tagsLoaded"
                placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.detail.label' | translate }}" *ngIf="isEdited">
  </app-select-multiple-tags>
  <ng-container *ngIf="!isEdited">
    <span *ngFor="let tag of parentFormGroup.value.tags" class="tag-badge tag-pill">
      {{ tag.name }}
    </span>
  </ng-container>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item
    class="card-items"
    lines="none"
    (click)="openItemSelectorPopup(tags, this.parentFormGroup.value.tags, 'tags', 'multiple_basic')">
    <ion-input
      type="text"
      readonly
      [placeholder]="'mobile-placeholder-tags' | translate"
      *ngIf="parentFormGroup.value.tags.length === 0">
    </ion-input>
    <ion-icon name="tag" color="primary" slot="start"></ion-icon>
    <span class="assets-container">
      <ion-chip color="chip" id="tag-badge" *ngFor="let tag of parentFormGroup.value.tags">
        <ion-label>#{{ tag.name }}</ion-label>
      </ion-chip>
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <div id="tags" *ngIf="tags.length > 0">
    <header class="label">{{customField.title}}</header>
    <ul id="tags-container">
      <li class="tag" *ngFor="let tag of tags">
        <span class="tag-pill tag-badge">{{tag.name}}</span>
      </li>
    </ul>
  </div>
</div>

