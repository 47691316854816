<div *ngIf="customField && isEdited && !isMobileEventPreviewComponent" class="custom-field" [ngClass]="{'custom-field-mobile': deviceIsMobile}">
  <div [formGroup]="parentFormGroup">
    <div formArrayName="customFields">
      <div *ngFor="let customFieldForm of customFields.controls">
        <div *ngIf="customFieldForm.value.id === customField.id && inputType=== 'number'" [formGroup]="customFieldForm">
          <label [ngClass]="{'custom-form-field-title': !deviceIsMobile}" class="mb-1">
            {{customField.title}}
          </label>
          <input *ngIf="inputType === 'number'" [type]="inputType" placeholder="{{ placeholderText | translate}}" formControlName="value"
            min="0" step="0.00001" oninput="validity.valid || (value='');"
            [ngClass]="{'number-field': inputType === 'number', 'custom-form-field-content': !deviceIsMobile}">
        </div>
        <div *ngIf="customFieldForm.value.id === customField.id && inputType !== 'number'" [formGroup]="customFieldForm">
          <label [ngClass]="{'custom-form-field-title': !deviceIsMobile}" class="mb-1">
            {{customField.title}}
          </label>
          <ion-textarea auto-grow = "true" *ngIf="inputType !== 'number'" (keydown.enter)="$event.stopPropagation()" id="description" formControlName="value"
            [readOnly]="!isEdited" placeholder="{{ placeholderText | translate }}" [ngClass]="{'custom-form-field-content web-customtextarea': !deviceIsMobile, 'is-edited' : inputType !== 'number'}"></ion-textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="customField && !isEdited && !isMobileEventPreviewComponent" class="custom-field" [ngClass]="{'custom-field-mobile': deviceIsMobile}">
  <div [formGroup]="parentFormGroup">
    <div formArrayName="customFields">
      <div *ngFor="let customFieldForm of customFields.controls">
        <div *ngIf="customFieldForm.value.id === customField.id && getCustomFieldValue(customFieldForm)" [formGroup]="customFieldForm"
          [ngClass]="{'custom-form-field-container': !deviceIsMobile}" class="custom-event-preview-webapp">
          <label [ngClass]="{'custom-form-field-title': !deviceIsMobile}" class="mb-0">
            {{customField.title}}
          </label>
          <div [ngClass]="{'custom-form-field-content': !deviceIsMobile}" appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea custom-padding custom-field-value" disabled>{{getCustomFieldValue(customFieldForm)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent && getCustomFieldValueFromEvent(customField)" class="custom-event-preview-mobile">
  <div>
    <ion-row>
      <ion-col>
        <label class="label">
          {{customField.title}}
        </label>
      </ion-col>
    </ion-row>
    <ion-row class="custom-field-value">
      <ion-col>
        <p>
          <textarea appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea" disabled>{{ getCustomFieldValueFromEvent(customField) }}</textarea>
        </p>
      </ion-col>
    </ion-row>
  </div>
</div>
