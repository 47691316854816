/* eslint-disable @typescript-eslint/member-ordering */
import { Session } from './../models/session';
import { Injectable } from '@angular/core';
import { SharedService } from './shared/shared.service';
import { UserRightsService } from './user-rights/user-rights.service';
import { DeviceService } from './device.service';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { SessionService } from './session.service';
import { StyleService } from '@services/style.service';
import { StripeService } from '@services/stripe.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AppTypeService } from './app-type.service';
import { SharedDataService } from './shared-data.service';
import { SubscriptionType, SubscriptionTypeHelper } from '@models/subscription/subscription-type';

export enum MenuTypes {
  SIDEBAR = 0,
  USER = 1,
}

export enum MenuItemType {
  route = 'route',
  link = 'link',
  action = 'action',
  label = 'label',
  spacer = 'spacer',
  version = 'version',
}

export class MenuItem {
  type: MenuItemType;
  key: string;
  label?: string;
  icon?: string;
  condition?: Observable<boolean>;
  color?: string;
  backgroundColor?: string;

  // route fields
  routeLink?: string;
  subRoutes?: MenuItem[];
  subscriptionHasFeature?: Observable<boolean>;

  // link fields
  link?: string;

  // action fields
  action?: string;

  // spacer fields
  cssClass?: string;
}

const ION_MENU_NAMES = {
  [MenuTypes.SIDEBAR]: 'main-menu',
  [MenuTypes.USER]: 'profil-menu',
};

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  session: Session;
  menuContent: { [key: number]: MenuItem[] } = {};
  MenuTypes = MenuTypes;

  menusOpenedSubject$ = new BehaviorSubject<{
    [MenuTypes.SIDEBAR]: boolean;
    [MenuTypes.USER]: boolean;
  }>({
    [MenuTypes.SIDEBAR]: false,
    [MenuTypes.USER]: false,
  });
  currentSpaceId: string;
  currentSiteId: string;

  constructor(
    private sharedService: SharedService,
    private deviceService: DeviceService,
    private userRightsService: UserRightsService,
    private sessionService: SessionService,
    public menuCtrl: MenuController,
    private styleService: StyleService,
    private stripeService: StripeService,
    private modalController: ModalController,
    private router: Router,
    public navController: NavController,
    private logger: NGXLogger,
    private appTypeService: AppTypeService,
    private sharedDataService: SharedDataService
  ) {
    // We initialize the menu content before loading any site or space the first time
    this.generateMenuContent(null, null);

    if (this.deviceService.isMobile) {
      combineLatest(sharedService.watchSpaceId, sharedService.watchSiteId, userRightsService.watchCurrentRoles()).subscribe(
        ([spaceId, siteId]) => {
          this.currentSpaceId = spaceId;
          this.currentSiteId = siteId;
          if (siteId) {
            this.generateMenuContent(spaceId, siteId);
          } else {
            this.generateSpaceDetails(spaceId);
          }
          this.generateUserMenuContent();
        }
      );
    } else {
      combineLatest(sharedDataService.watchSpaceId, sharedDataService.watchSiteId, userRightsService.watchCurrentRoles()).subscribe(
        ([spaceId, siteId]) => {
          this.currentSpaceId = spaceId;
          this.currentSiteId = siteId;
          if (siteId) {
            this.generateMenuContent(spaceId, siteId);
          } else {
            this.generateSpaceDetails(spaceId);
          }
          this.generateUserMenuContent();
        }
      );
    }
    this.generateUserMenuContent();
  }

  public generateSpaceDetails(currentSpaceId: string) {
    const routeSpacePrefix = currentSpaceId ? `/space/${currentSpaceId}` : null;

    const canManageSpaceResources = routeSpacePrefix &&
      this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.space.resources.manage);

    const canManageDashboards = routeSpacePrefix &&
      this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.space.dashboards);


    /**
     * If application is run on mobile browser, we show subscription related
     * elements on the sidebar. Since this feature is not present on native applications,
     * the listening to changes on status and invoices is redundant. For web applications, the subscription
     * sidebar item is always present, making the observable subscription redundant here as well.
     * So we specifically check for mobile browser and subscribe only if necessary.
     */
    if (this.deviceService.isMobile && this.deviceService.isMobileWeb) {
      let subscriptionState: SubscriptionType;
      combineLatest([
        this.stripeService.forCurrentSpace.status,
        this.stripeService.forCurrentSpace.invoices
      ]).subscribe(([status, invoices]) => {
        subscriptionState = SubscriptionTypeHelper.getSubscriptionStateBasedOnStatus(status, (invoices.length !== 0));
        let subscriptionItems: MenuItem[];
        switch (subscriptionState) {
          case SubscriptionType.FREE_VERSION:
          case SubscriptionType.TRAILING:
            subscriptionItems = [{
              type: MenuItemType.route,
              key: 'no-subscription',
              label: 'mobile.menu.subscription',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/no-subscription',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }, {
              type: MenuItemType.route,
              key: 'faq',
              label: 'mobile.menu.faq',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/faq',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }];
            break;
          case SubscriptionType.SUBSCRIPTION:
            subscriptionItems = [{
              type: MenuItemType.route,
              key: 'subscription',
              label: 'mobile.menu.subscription',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/subscription',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }, {
              type: MenuItemType.route,
              key: 'payment-information',
              label: 'mobile.menu.payment-information',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/payment-information',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }];
            break;
          case SubscriptionType.FREE_VERSION_POST_SUBSCRIPTION:
            subscriptionItems = [{
              type: MenuItemType.route,
              key: 'no-subscription',
              label: 'mobile.menu.subscription',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/no-subscription',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }, {
              type: MenuItemType.route,
              key: 'payment-information',
              label: 'mobile.menu.payment-information',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/payment-information',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }, {
              type: MenuItemType.route,
              key: 'faq',
              label: 'mobile.menu.faq',
              icon: 'cash-outline',
              color: this.appTypeService.getAppSpecificColor(),
              backgroundColor: this.styleService.get(
                'menu-whitesmoke-background-color'
              ),
              routeLink: routeSpacePrefix + '/users/faq',
              condition: of(routeSpacePrefix !== null && canManageSpaceResources),
            }];
            break;
        }
        this.menuContent[MenuTypes.SIDEBAR] = [
          {
            type: MenuItemType.label,
            key: 'space_settings',
            label: 'menu.space_settings',
          },
          {
            type: MenuItemType.route,
            key: 'space_details',
            label: 'menu.space_details',
            icon: 'building',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: routeSpacePrefix + '/edit',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'global-resources',
            label: 'menu.resources',
            icon: 'crane-asset',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: routeSpacePrefix + '/resources-mobile',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'space',
            label: 'menu.information',
            icon: 'information',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: routeSpacePrefix + '/information-mobile',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'event-form',
            label: 'menu.event_form',
            icon: 'reader-outline',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: routeSpacePrefix + '/event-form',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'team',
            label: 'mobile.menu.users',
            icon: 'team',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: routeSpacePrefix + '/users/list',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          ...subscriptionItems,
          {
            type: MenuItemType.spacer,
            key: 'middle-space',
            cssClass: 'menu-spacer',
          },
          {
            type: MenuItemType.label,
            key: 'help',
            label: 'menu.help',
          },
          {
            type: MenuItemType.version,
            key: 'version',
            label: 'menu.version',
          },
          {
            type: MenuItemType.route,
            key: 'site-productivity-apps',
            label: 'site_productivity_apps.sideBar_menu',
            icon: 'grid',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(
              'menu-whitesmoke-background-color'
            ),
            routeLink: '/site-productivity-apps',
          },
          {
            type: MenuItemType.action,
            key: 'chat',
            label: 'menu.support',
            icon: 'lifeline',
            action: 'chat',
          },
        ];
      });
    } else {
      if (this.deviceService.isMobile) {
        this.menuContent[MenuTypes.SIDEBAR] = [{
          type: MenuItemType.label,
          key: 'space_settings',
          label: 'menu.space_settings',
        },
        {
          type: MenuItemType.route,
          key: 'space_details',
          label: 'menu.space_details',
          icon: 'building',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: routeSpacePrefix + '/edit',
          condition: of(routeSpacePrefix !== null && canManageSpaceResources),
        },
        {
          type: MenuItemType.route,
          key: 'global-resources',
          label: 'menu.resources',
          icon: 'crane-asset',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: routeSpacePrefix + (this.deviceService.isMobile ? '/resources-mobile' : '/resources'),
          condition: of(routeSpacePrefix !== null && canManageSpaceResources),
        },
        {
          type: MenuItemType.route,
          key: 'space',
          label: 'menu.information',
          icon: 'information',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: routeSpacePrefix + (this.deviceService.isMobile ? '/information-mobile' : '/information'),
          condition: of(routeSpacePrefix !== null &&  canManageSpaceResources),
        },
        {
          type: MenuItemType.route,
          key: 'event-form',
          label: 'menu.event_form',
          icon: 'reader-outline',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: routeSpacePrefix + '/event-form',
          condition: of(routeSpacePrefix !== null && canManageSpaceResources),
        },
        {
          type: MenuItemType.route,
          key: 'team',
          label: 'mobile.menu.users',
          icon: 'team',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: routeSpacePrefix + '/users',
          condition: of(routeSpacePrefix !== null && canManageSpaceResources),
        },
        {
          type: MenuItemType.spacer,
          key: 'middle-space',
          cssClass: 'menu-spacer'
        },
        {
          type: MenuItemType.label,
          key: 'help',
          label: 'menu.help',
        },
        {
          type: MenuItemType.version,
          key: 'version',
          label: 'menu.version',
        },
        {
          type: MenuItemType.route,
          key: 'site-productivity-apps',
          label: 'site_productivity_apps.sideBar_menu',
          icon: 'grid',
          color: this.appTypeService.getAppSpecificColor(),
          backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
          routeLink: '/site-productivity-apps',
          condition: of(this.deviceService.isMobile),
        },
        {
          type: MenuItemType.action,
          key: 'chat',
          label: 'menu.support',
          icon: 'lifeline',
          action: 'chat',
        },
        {
          type: MenuItemType.action,
          key: 'collapse',
          label: 'btn.collapse',
          icon: 'arrow-back',
          action: 'collapse',
          condition: of(!this.deviceService.isMobile),
        },
        ];
      } else {
        this.menuContent[MenuTypes.SIDEBAR] = [
          {
            type: MenuItemType.label,
            key: 'space_info',
            label: 'menu.categories',
            color: '#F54900',
            condition: of(canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'space_details',
            label: 'menu.space_details',
            icon: 'building',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + '/edit',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'dashboards',
            label: 'menu.dashboards',
            cssClass: 'dashboards-icon',
            icon: 'dashboards-icon',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + '/dashboards',
            condition: of(routeSpacePrefix !== null && !this.deviceService.isMobile && canManageDashboards),
          },
          {
            type: MenuItemType.route,
            key: 'global-resources',
            label: 'menu.resources',
            icon: 'crane-asset',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + (this.deviceService.isMobile ? '/resources-mobile' : '/resources'),
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'space',
            label: 'menu.information',
            icon: 'information',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + (this.deviceService.isMobile ? '/information-mobile' : '/information'),
            condition: of(routeSpacePrefix !== null &&  canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'event-form',
            label: 'menu.event_form',
            icon: 'reader-outline',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + '/event-form',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.route,
            key: 'team',
            label: 'menu.users_and_subscription',
            icon: 'team',
            color: this.appTypeService.getAppSpecificColor(),
            backgroundColor: this.styleService.get(this.appTypeService.isInAdministrationPage() ?
              'menu-orange-background-color' : 'menu-whitesmoke-background-color'),
            routeLink: routeSpacePrefix + '/users',
            condition: of(routeSpacePrefix !== null && canManageSpaceResources),
          },
          {
            type: MenuItemType.spacer,
            key: 'middle-space',
            cssClass: 'menu-spacer'
          },
          {
            type: MenuItemType.label,
            key: 'help',
            label: 'menu.help',
          },
          {
            type: MenuItemType.version,
            key: 'version',
            label: 'menu.version',
          },
          {
            type: MenuItemType.action,
            key: 'chat',
            label: 'menu.support',
            icon: 'lifeline',
            action: 'chat',
          },
          {
            type: MenuItemType.action,
            key: 'collapse',
            label: 'btn.collapse',
            icon: 'arrow-back',
            action: 'collapse',
            condition: of(!this.deviceService.isMobile),
          },
        ];
      }
    }
  }

  private generateMenuContent(currentSpaceId: string, currentSiteId: string) {
    const routeSpacePrefix = currentSpaceId ? `/space/${currentSpaceId}` : null;
    const routeSitePrefix = (routeSpacePrefix && currentSiteId) ? `${routeSpacePrefix}/site/${currentSiteId}` : null;

    const canEditSite = routeSitePrefix && this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.site.edit);
    const canManageSiteTeam = routeSitePrefix && this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.site.team.manage);
    const canManageSiteResources = routeSitePrefix && this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.site.resources.manage);
    const canManageSpaceResources = routeSpacePrefix &&
      this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.space.resources.manage);
    const canManageDashboards = routeSpacePrefix &&
      this.userRightsService.hasRight(UserRightsService.USER_RIGHTS.site.dashboards);

    this.menuContent[MenuTypes.SIDEBAR] = [
      {
        type: MenuItemType.label,
        key: 'categories',
        label: 'menu.categories',
        color: this.appTypeService.getAppSpecificColor(),
        condition: of(routeSitePrefix !== null),
      },
      {
        type: MenuItemType.route,
        key: 'tasks',
        label: 'menu.tasks',
        icon: 'tasks',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + '/tasks',
        condition: of(routeSitePrefix !== null && !this.appTypeService.checkRouteForAppType()),
      },
      {
        type: MenuItemType.route,
        key: 'events',
        label: 'menu.events',
        icon: 'events',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + '/events',
        condition: of(routeSitePrefix !== null && this.appTypeService.checkRouteForAppType()),
      },
      {
        type: MenuItemType.route,
        key: 'dashboards',
        label: 'menu.dashboards',
        cssClass: 'dashboards-icon',
        icon: 'dashboards-icon',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + (this.appTypeService.getCurrentAppType() === 'diary' ?
          '/site-diary-dashboards' : '/site-task-dashboards'),
        condition: of(routeSitePrefix !== null && !this.deviceService.isMobile && canManageDashboards),
      },
      {
        type: MenuItemType.route,
        key: 'site',
        label: 'menu.site_settings',
        icon: 'settings',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + '/settings',
        condition: of(routeSitePrefix !== null && canEditSite),
      },
      {
        type: MenuItemType.route,
        key: 'site-resources',
        label: 'menu.resources',
        icon: 'crane-asset',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + (this.deviceService.isMobile ? '/resources-mobile' : '/resources'),
        condition: of(routeSitePrefix !== null && (canManageSiteResources || canManageSpaceResources)),
      },
      {
        type: MenuItemType.route,
        key: 'site-information',
        label: 'menu.information',
        icon: 'information',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + (this.deviceService.isMobile ? '/information-mobile' : '/information'),
        condition: of(routeSitePrefix !== null && (canManageSiteResources || canManageSpaceResources)),
      },
      {
        type: MenuItemType.route,
        key: 'team',
        label: 'menu.team',
        icon: 'team',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: routeSitePrefix + '/users',
        condition: of(routeSitePrefix !== null && canManageSiteTeam),
      },
      {
        type: MenuItemType.spacer,
        key: 'middle-space',
        cssClass: 'menu-spacer'
      },
      {
        type: MenuItemType.label,
        key: 'help',
        label: 'menu.help',
      },
      {
        type: MenuItemType.version,
        key: 'version',
        label: 'menu.version',
      },
      {
        type: MenuItemType.route,
        key: 'site-productivity-apps',
        label: 'site_productivity_apps.sideBar_menu',
        icon: 'grid',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        routeLink: '/site-productivity-apps',
        condition: of(this.deviceService.isMobile),
      },
      {
        type: MenuItemType.action,
        key: 'chat',
        label: 'menu.support',
        icon: 'lifeline',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        action: 'chat',
      },
      {
        type: MenuItemType.action,
        key: 'collapse',
        label: 'btn.collapse',
        icon: 'arrow-back',
        color: this.appTypeService.getAppSpecificColor(),
        backgroundColor: this.styleService.get('menu-whitesmoke-background-color'),
        action: 'collapse',
        condition: of(!this.deviceService.isMobile),
      },
    ];
  }

  public updateMenuContent(): void {
    this.generateMenuContent(this.currentSpaceId, this.currentSiteId);
    this.generateUserMenuContent();
  }

  public isSideMenuDisplayed(): boolean {
    if (this.router.url.includes(this.currentSpaceId + '/select-site')) {
      return false;
    } else {
      return true;
    }
  }

  public generateUserMenuContent(){
    this.menuContent[MenuTypes.USER] = [{
      type: MenuItemType.label,
      key: 'email',
      cssClass: 'email',
      color: this.appTypeService.getAppSpecificColor(),
      // eslint-disable-next-line max-len
      label: this.sessionService.getCurrentUser() ? this.sessionService.getCurrentUser().mail : '', // TODO: This is a temporary quick fix and needs to be fixed correctly
      condition: of(!this.deviceService.isMobile),
    },
    {
      type: MenuItemType.route,
      key: 'account',
      label: 'header.account',
      routeLink: '/profile',
      icon: 'custom-profil',
      color: this.appTypeService.getAppSpecificColor(),
    },
    {
      type: MenuItemType.action,
      key: 'support-tools',
      label: 'menu.support-tools',
      icon: 'lifeline',
      action: 'support-tools',
      condition: of(this.isMobileDeviceWithSpaceSelected()),
    },
    {
      type: MenuItemType.action,
      key: 'export-logs',
      label: 'menu.export-logs',
      icon: 'book-outline',
      action: 'export-logs',
    },
    {
      type: MenuItemType.spacer,
      key: 'middle-space',
      cssClass: 'menu-spacer menu-spacer-border',
      condition: of(this.deviceService.isMobile),
    },
    {
      type: MenuItemType.action,
      key: 'logout',
      label: 'header.log_out',
      icon: 'custom-logout',
      action: 'logout',
    }];
  }

  public getMenuContent(menuType: MenuTypes): MenuItem[] {
    if (this.deviceService.isMobile) {
      return (menuType in this.menuContent) ? this.menuContent[menuType].filter(item => item.key !== 'help') : [];
    }
    return (menuType in this.menuContent) ? this.menuContent[menuType] : [];
  }

  public openMenu(menuType: MenuTypes) {
    if (this.deviceService.isMobile) {
      if (menuType in ION_MENU_NAMES) {
        this.menuCtrl.enable(true, ION_MENU_NAMES[menuType]).then(() =>
          this.menuCtrl.open(ION_MENU_NAMES[menuType]));
      } else {
        this.logger.error('Trying to open a menu that does not exist: ', menuType);
      }
    } else {
      this.communicateMenuOpen(menuType, true);
    }
  }

  public closeMenu(menuType: MenuTypes) {
    if (this.deviceService.isMobile) {
      if (menuType in ION_MENU_NAMES) {
        this.menuCtrl.close(ION_MENU_NAMES[menuType]).then(() =>
          this.menuCtrl.enable(true, ION_MENU_NAMES[menuType]));
      } else {
        this.logger.error('Trying to close a menu that does not exist: ', menuType);
      }
    } else {
      if (menuType === MenuTypes.SIDEBAR) {
        // We never close the sidebar on desktop
      } else {
        this.communicateMenuOpen(menuType, false);
      }
    }
  }

  public toggleMenu(menuType: MenuTypes) {
    if (this.isMenuOpened(menuType)) {
      this.closeMenu(menuType);
    } else {
      this.openMenu(menuType);
    }
  }

  public isMenuOpened(menuType: MenuTypes): boolean {
    return this.menusOpenedSubject$.getValue()[menuType];
  }

  public communicateMenuOpen(menuType: MenuTypes, isOpened: boolean): void {
    const currentState = this.menusOpenedSubject$.getValue();
    currentState[menuType] = isOpened;
    this.menusOpenedSubject$.next(currentState);
  }

  /**
   * Process the right action when user is pressing the mobile back button
   * Actions possibilities list:
   * - Closing sidebar
   * - Closing site selector
   * - Closing user profile menu
   * __Default__: Closing the last open Tab to get back onto navigation OR closing last opened modal
   */
  public processActionOnBackButtonEvent(): void {
    if (this.isMenuOpened(MenuTypes.SIDEBAR)) {
      this.closeMenu(MenuTypes.SIDEBAR);
    } else if (this.isMenuOpened(MenuTypes.USER)) {
      this.closeMenu(MenuTypes.USER);
    } else {
      this.modalController.getTop().then(modal => {
        if (modal) {
          this.modalController.dismiss();
        } else {
          this.navController.back();
        }
      });
    }
  }

  isMobileDeviceWithSpaceSelected(): boolean {
    if (this.deviceService.isMobile && this.currentSpaceId !== null) {
      return true;
    } else {
      return false;
    }
  }
}
