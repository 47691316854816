import { ModelElement } from "./model-element";
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_LABEL } from "./notification-type";

export interface NOTIFICATION_CONFIGURATIONS_MODEL {
  id: string;
  notificationType: NOTIFICATION_TYPE;
  isEmailSwitchedOn: boolean;
  label: NOTIFICATION_TYPE_LABEL;
}

export enum NOTIFICATION_CONFIGURATION_TYPE {
  EMAIL = "EMAIL",
}

export interface BENotificationConfigurationsResponse {
  id: string;
  email: boolean;
  type: NOTIFICATION_TYPE
}

export class NotificationConfiguration {
  id: string;
  notificationType: NOTIFICATION_TYPE;
  isEmailSwitchedOn: boolean;

  public static NOTIFICATION_CONFIGURATIONS_MODEL: NOTIFICATION_CONFIGURATIONS_MODEL[] = [
    {
      id: ModelElement.generateId(),
      notificationType: NOTIFICATION_TYPE.EVENT_REJECTION,
      isEmailSwitchedOn: false,
      label: NOTIFICATION_TYPE_LABEL.EVENT_REJECTION,
    },
    {
      id: ModelElement.generateId(),
      notificationType: NOTIFICATION_TYPE.TASK_ASSIGNMENT,
      isEmailSwitchedOn: false,
      label: NOTIFICATION_TYPE_LABEL.TASK_ASSIGNMENT,
    },
    {
      id: ModelElement.generateId(),
      notificationType: NOTIFICATION_TYPE.TASK_COMPLETION,
      isEmailSwitchedOn: false,
      label: NOTIFICATION_TYPE_LABEL.TASK_COMPLETION,
    },
    {
      id: ModelElement.generateId(),
      notificationType: NOTIFICATION_TYPE.TAG_NOTIFICATION,
      isEmailSwitchedOn: false,
      label: NOTIFICATION_TYPE_LABEL.TAG_NOTIFICATION,
    },
  ]

  constructor(
    id: string = ModelElement.generateId(),
    notificationType: NOTIFICATION_TYPE = null,
    email: boolean = false,
  ) {
    this.id = id;
    this.notificationType = notificationType;
    this.isEmailSwitchedOn = email;
  }

  public static toDTO(notificationConfigurations: NotificationConfiguration[]): BENotificationConfigurationsResponse[] {
    let jsonBody: BENotificationConfigurationsResponse[] = [];
    jsonBody = notificationConfigurations.map(notificationConfiguration => {
      const json: BENotificationConfigurationsResponse = {
        id: notificationConfiguration.id,
        type: notificationConfiguration.notificationType,
        email: notificationConfiguration.isEmailSwitchedOn
      };
      return json;
    });
    return jsonBody;
  }

  public static toModel(jsonResponse: BENotificationConfigurationsResponse[]): NotificationConfiguration[] {
    let notificationConfiguration: NotificationConfiguration[] = [];
    if(jsonResponse.length > 0) {
      jsonResponse.forEach((_value) => {
        let configuration: NotificationConfiguration = {
          id: _value.id,
          isEmailSwitchedOn: _value.email,
          notificationType: _value.type
        }
        notificationConfiguration.push(configuration);
      });
    }
    return notificationConfiguration;
  }
}
