import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CustomEventField, allLegacyFieldTypes, CustomEventLegacyFieldType } from '@models/custom-event-field';
import { CustomEventForm } from '@models/custom-event-form';
import { CustomEventFormSection } from '@models/custom-event-form-layout';
import { customFormLegacyFormLayout } from '@models/customFormLayout/custom-form-legacy-form-layout';
import { NetworkStatus } from '@models/synchronization/network-status';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { AbstractModelService } from './abstract-model-service';
import { DeviceService } from './device.service';
import { SharedDataService } from './shared-data.service';
import { DatabaseService } from './shared/database.service';
import { SharedService } from './shared/shared.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';

@Injectable({
  providedIn: 'root'
})

export class CustomEventFormService extends AbstractModelService<CustomEventForm> {

  protected type = 'CustomEventForm';
  private tenantUrl: string;
  private headers: HttpHeaders

  private allLegacyFields = JSON.parse(JSON.stringify(allLegacyFieldTypes));

  private customFormLayout$ = new BehaviorSubject<CustomEventFormSection[]>([]);
  private customFormFields$ = new BehaviorSubject<CustomEventField[]>([]);
  private customFormLegacyFields$ = new BehaviorSubject<CustomEventLegacyFieldType[]>(this.allLegacyFields);
  public customFormLayout = this.customFormLayout$.asObservable();
  public customFormFields = this.customFormFields$.asObservable();
  public customFormLegacyFields = this.customFormLegacyFields$.asObservable();
  currentSpaceId: string;
  isSitePresentInSpace: boolean = true;

  constructor(
    protected databaseService: DatabaseService,
    private logger: NGXLogger,
    private http: HttpClient,
    private urlGiverService: UrlGiverService,
    private sharedService: SharedService,
    private toasterService: ToasterService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService
  ) {
    super(
      databaseService,
    )
    this.tenantUrl = urlGiverService.giveTenantAPIUrl();
    this.headers = this.urlGiverService.httpOptions.headers;
    if (this.deviceService.isMobile) {
      this.sharedService.watchSpaceId.subscribe(spaceId => {
        this.currentSpaceId = spaceId;
      });
    }
    else {
      this.sharedDataService.watchSpaceId.subscribe(spaceId => {
        this.currentSpaceId = spaceId;
      });
    }
  }

  updateCustomFormLayout(newLayout: CustomEventFormSection[]): void {
    this.customFormLayout$.next(newLayout);
  }

  updateCustomFormFields(newFields: CustomEventField[]): void {
    this.customFormFields$.next(newFields);
  }

  updateCustomFormLegacyFields(legacyFields: CustomEventLegacyFieldType[]): void {
    this.customFormLegacyFields$.next(legacyFields);
  }

  public async updateForm(currentForm: CustomEventForm): Promise<boolean> {
    return this.postNewForm(currentForm);
  }

  private async postNewForm(form: CustomEventForm): Promise<boolean> {
    const formCopy = form;
    const payload = formCopy.toDTO();
    let formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    if(NetworkStatus.isOnline) {
      return this.http.post(formUrl, payload, {headers: this.headers}).toPromise()
      .then(async (response) => {
        this.logger.debug('Created new custom form successfully.', response);
        if (this.deviceService.isMobile) {
          await this.setOtherFormsToInactive(formCopy.id);
        }
        return true;
      }).catch((error) => {
        this.logger.error("Error encountered while saving the custom form with the server.", error);
        if(error.error.status === 400 && error.error.message === "Duplicate form name") {
          throw new Error('Duplicate form name');
        }
        return false;
      });
    } else {
      this.toasterService.showWarningToaster('forbidden-offline');
      return false;
    }

  }

  async resetToDefaultForm(): Promise<boolean> {
    let formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    if(NetworkStatus.isOnline) {
      return this.http.put(formUrl, null, {headers: this.headers}).toPromise()
      .then((response) => {
        this.logger.debug('Reset to default form successfully.', response);
        return true;
      }).catch((error) => {
        this.logger.error("Error encountered while trying to reset to the default form with the server.", error);
        return false;
      });
    } else {
      this.toasterService.showWarningToaster('forbidden-offline');
      return false;
    }
  }

  async getCurrentActiveFormFromDB(siteId?: string): Promise<CustomEventForm | null> {
    try {
      const db = await this.databaseService.getDBInstant();
      const allForms = await db.customEventForm.toArray();
      let activeForm = allForms.filter((form) => form.isSelected === true);
      if(siteId) {
        activeForm = activeForm.filter((form) => form.siteId === siteId);
      }
      if(activeForm[0]) {
        return activeForm[0];
      } else {
        return null;
      }
    } catch(error) {
      this.logger.error("Error while fetching active custom event form from DB.", error);
      return null;
    }
  }

  async getFormFromBackend(): Promise<CustomEventForm | null> {
    let formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    const newActiveForm =  await this.http.get(formUrl, {headers: this.headers}).toPromise()
    .then((response) => {
      let customForm = new CustomEventForm();
      CustomEventForm.convertSpaceFormDTOToModel(response, customForm);
      return customForm;
    }).catch((error) => {
      if(error.error?.errorCode === "NO_SITE_IN_SPACE"){
        this.isSitePresentInSpace = false;
      }
      this.logger.error('Error while fetching latest active form from server.', error);
      return null;
    });
    return newActiveForm;
  }

  async getCurrentEventCustomFormFromDB(formId: string): Promise<CustomEventForm | null> {
    try {
      if (formId) {
        const db = await this.databaseService.getDBInstant();
        const activeForm = await db.customEventForm.where('id').equals(formId).toArray();
        if(activeForm[0]) {
          return activeForm[0];
        } else {
          return null;
        }
      } else {
        this.logger.error('Event does not have form layout ID.');
        return null;
      }
    } catch(error) {
      this.logger.error("Error while fetching current event custom form from DB.", error);
      return null;
    }
  }

  async getLegacyFormLayout(): Promise<CustomEventForm> {
    let customLegacyForm = new CustomEventForm();
    CustomEventForm.toModel(customFormLegacyFormLayout, customLegacyForm);
    return customLegacyForm;
  }

  async getAllFormTitlesFromDB(): Promise<string[]> {
    const allFormTitles = [];
    const db = await this.databaseService.getDBInstant();
    const allForms = await db.customEventForm.toArray();
    allForms.forEach((form) => {
      allFormTitles.push(form.title);
    });
    return allFormTitles;
  }

  /**
   * Set all other forms except for the forms with the matching formId to inactive.
   */
  async setOtherFormsToInactive(formId: string) {
    const db = await this.databaseService.getDBInstant();
    await db.customEventForm.toCollection().modify(form => {
      if(form.id !== formId) {
        form.isSelected = false;
      }
    });
  }
}
