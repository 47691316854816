import { InternationalizationService } from './internationalization.service';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const LOCALIZED_DATE_LABELS = {
  'en': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  },
  'zh': {
    weekdays: ['"星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
    months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  }
  // In the future, other languages that we support should be added here
};

/**
 * Date picker i18n
 */
@Injectable({
  providedIn: 'root'
})
export class CustomDatepickerI18nService {

  private readonly lang: string;

  constructor(
    private i18n: InternationalizationService
  ) {
    this.lang = i18n.currentLocale.split('-')[0];
  }

  /**
   * Method returning localized list of month
   */
  getMonthList(): string[] {
    return LOCALIZED_DATE_LABELS[this.lang].months;
  }

  /**
   * Method returning localized date picker format
   */
  getPickerDisplayFormat(): string {
    if (this.i18n.isCrazyLanguage()) {
      return 'MM/dd/yyyy HH:mm';
    } else {
      return 'dd/MM/yyyy H:mm';
    }
  }
  getPickerDisplayFormatWithOutTime(): string {
    if (this.i18n.isCrazyLanguage()) {
      return 'MM-dd-yyyy';
    } else {
      return 'dd-MM-yyyy';
    }
  }

  getWeekdayShortName(weekday: number): string {
    return LOCALIZED_DATE_LABELS[this.lang].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return LOCALIZED_DATE_LABELS[this.lang].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

}


/**
 * Datepicker parser
 *
 */
@Injectable()
export class NgbDateParserFormatterI18N {

  private lang: string;

  constructor(i18n: InternationalizationService) {
    this.lang = i18n.currentLocale;
  }

  format(date: NgbDateStruct): string {
    let result: string = '';
    if (date) {
      const d = new Date(date.year, date.month - 1, date.day);
      result = d.toLocaleDateString(this.lang);
    }
    return result;
  }
}
