import { Component } from '@angular/core';
import { SelectMultipleComponent } from '../select-multiple.component';

@Component({
  selector: 'app-select-multiple-tags',
  templateUrl: './select-multiple-tags.component.html',
  styleUrls: ['./select-multiple-tags.component.sass']
})
export class SelectMultipleTagsComponent extends SelectMultipleComponent {

}
