<div class="main-form-wrapper" [class.invalid]="formGroup.invalid" (keydown.enter)="$event.preventDefault()">

  <ion-header class="secondary-header" mode="ios">
    <app-events-form-mobile-toolbar
      doneLabel="ok"
      [title]="title"
      (back)="getBack()"
      (saveDraft)="beforeSave(EventStatus.DRAFT)"
      (saveSubmit)="prepareSubmitEvent()"
      [disableDoneButton]="isCustomEventFormNotAvailable">
    </app-events-form-mobile-toolbar>
  </ion-header>

  <div [ngClass] = "isTabletInLandscape ? 'tablet-landscape' : '' ">
    <ion-list justify-content-center align-items-center no-margin class="event-form-background">
      <form [formGroup]="formGroup">

        <div *ngIf="isCustomEventFormNotAvailable">
          <ion-card>
            <ion-card-content>
              {{'custom_event.no_form_found.error_message' | translate }}
            </ion-card-content>
          </ion-card>
        </div>

        <div *ngIf="isFaultyEvent && itemIdToDuplicate === null">
          <ion-card class="faulty-event">
            <ion-card-content>
              {{ faultyErrorString | async | translate }}
            </ion-card-content>
          </ion-card>
        </div>

        <ion-card>
          <ion-card-content>
            <ion-label color="primary">
             <b class="item-headings">{{'events.edit.field.title' | translate}} {{'wildcard.symbol' | translate}}</b>
            </ion-label>
            <ion-item
            lines="none"
            [class.invalid]="formGroup.controls.title.invalid && formGroup.controls.title.dirty">
              <div class="title-input">
                <ion-input type="text" formControlName="title" placeholder="{{'mobile-placeholder-title' | translate}}"></ion-input>
                <ion-text color="danger" *ngIf="getControlErrors('title') != null">
                  <small>{{ getControlErrors('title') }}</small>
                </ion-text>
              </div>
            </ion-item>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-content>
            <ion-label color="primary">
             <b class="item-headings">{{'info' | translate}} {{'wildcard.symbol' | translate}}</b>
            </ion-label>
            <ion-grid [ngClass] = "isTabletInLandscape ? 'tablet-information-container' : '' ">
              <ion-row>
                <ion-col size="6">
                  <ion-label class="date-input">
                    <b class="items-label">{{'ui.datetimepicker.date.start' | translate}}</b>
                    <ion-item
                      lines="full"
                      (click)="startDate.openDatetimePicker()"
                      [class.invalid]="formGroup.controls.startDatetime.invalid && formGroup.controls.startDatetime.dirty">
                      <span >
                        <ion-text class="start-date">
                          <app-datetime-input
                            #startDate
                            [canClearOnlyEndDatetime]="canClearEndDatetime"
                            [type]="'startDatetime'"
                            [placeholder]="'mobile-placeholder-startdate' | translate"
                            formControlName="startDatetime">
                          </app-datetime-input>
                        </ion-text>
                        <br>
                        <ion-text color="danger" *ngIf="getControlErrors('startDatetime')">
                          <small>{{getControlErrors('startDatetime') }}</small>
                        </ion-text>
                      </span>
                    </ion-item>
                  </ion-label>
                </ion-col>
                <ion-col size="6">
                  <ion-label class="date-input">
                    <b class="items-label">{{'tasks.detail.panel_details.end_date' | translate}}</b>
                    <ion-item
                      lines="full"
                      (click)="endDate.openDatetimePicker()"
                      [class.invalid]="formGroup.hasError('negativeDuration') || formGroup.hasError('moreThanTwentyFourHourDuration')">
                      <span>
                        <ion-text>
                          <app-datetime-input
                            #endDate
                            [canClearOnlyEndDatetime]="canClearEndDatetime"
                            [placeholder]="'mobile-placeholder-enddate' | translate"
                            [defaultDate]="defaultEndDate"
                            [clearable]="true"
                            type="endDatetime"
                            formControlName="endDatetime">
                          </app-datetime-input>
                        </ion-text>
                        <br>
                        <ion-text color="danger" *ngIf="formGroup.hasError('negativeDuration') || formGroup.hasError('moreThanTwentyFourHourDuration')">
                          <small>{{ getFormErrors('negativeDuration', 'moreThanTwentyFourHourDuration') }}</small>
                        </ion-text>
                      </span>
                    </ion-item>
                  </ion-label>
                </ion-col>
                <ion-col size="4" *ngIf="isDurationDisplayed()">
                  <ion-label class="date-input">
                    <b class="items-label">{{'events.edit.field.duration' | translate}}</b>
                    <ion-item lines="none">
                      <span >
                        <ion-text class="duration-input">
                          {{ formGroup.value.startDatetime | hourDiffBetweenTwoDates : formGroup.value.endDatetime }}
                        </ion-text>
                      </span>
                    </ion-item>
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>

            <!-- Weather is separated in multiple ion-item (multi-lines item) -->
            <!-- weather header -->
            <ion-item lines="none" class="weather-header">
              <b>{{ 'weather_location' | translate }}</b>
              <div *ngIf="isWeatherDataLoading" class="spinner-border spinner-border-sm ml-1"></div>
            </ion-item>

            <!-- weather main content -->
            <ion-grid [ngClass] = "isTabletInLandscape ? 'tablet-information-container' : '' ">
              <ion-row>
                <ion-col size="8" class="ion-no-padding ion-no-margin">
                  <ng-container *ngIf="weatherCodeDetails && isPreviewingWeather && event && event.weather && !event.weather.ignored;then weather_available else weather_unavailable;"></ng-container>
                </ion-col>
                <ion-col size="4">
                  <!-- weather options buttons -->
                  <ion-item lines="none" *ngIf="event && event.weather">
                    <app-select-weather
                      [defaultLat]="event?.weather?.latitude"
                      [defaultLng]="event?.weather?.longitude" [readOnly]="!isEdited"
                      (weatherActiveEmitter)="onWeatherActiveEvent($event)"
                      (weatherChangeEmitter)="onCoordinatesChanged($event)">
                    </app-select-weather>
                  </ion-item>
                  <button *ngIf="weatherApiInvalidResponse" class="btn-refresh-weather mobile-form" (click)="previewWeather()" color="secondary">
                    <span>{{ 'label.refresh' | translate }}</span>
                  </button>
                </ion-col>
                <ng-template #weather_available>
                  <ion-item lines="none" class="ion-no-padding ion-no-margin">
                    <ion-col size="3" class="ion-no-padding ion-no-margin">
                      <div>
                        <ion-thumbnail slot="start">
                          <img [src]="weatherCodeDetails?.icon_path">
                        </ion-thumbnail>
                      </div>
                    </ion-col>
                    <ion-col size="4" class="ion-no-padding ion-no-margin">
                      <ion-label class="weather-info">
                        <div >
                          <div class="temperature">
                            {{ event?.weather.temperature | weatherTemperaturePipe |async}}
                          </div>
                        </div>
                      </ion-label>
                      <p class="weather-code-label">{{ weatherCodeDetails?.label }}</p>
                    </ion-col>
                    <ion-col size="5" class="ion-no-padding ion-no-margin">
                      <ion-label class="weather-label">
                          <div class="details">
                            {{ event?.weather.windSpeed | weatherWindspeedPipe |async}}
                            <br>
                            <p>{{ event?.weather.humidity }}{{'app.weather.humidity' | translate }}</p>
                          </div>
                      </ion-label>
                    </ion-col>
                  </ion-item>
                </ng-template>

                <ng-template #weather_unavailable>
                  <ion-item lines="none" class="ion-no-padding ion-no-margin" *ngIf="event">
                    <ion-col size = "3" class="ion-no-padding ion-no-margin">
                      <div>
                        <ion-thumbnail slot="start">
                          <img [src]="'/assets/img/weather/' + (isOnline ? (event.weather == null ? 'weather_not_filled.svg' : ( event.weather.ignored == true ? 'weather_ignored.svg' : 'weather_pending.svg')) : 'weather_ignored.svg')">
                        </ion-thumbnail>
                      </div>
                    </ion-col>
                    <ion-col size="9" class="ion-no-padding ion-no-margin">
                      <div *ngIf="!weatherApiInvalidResponse" [class]="isOnline ? (event.weather == null ? '' : ( event.weather.ignored == true ? 'weather-ignored' : 'weather-pending')) : 'weather-ignored'">
                        {{ (isOnline ? (event.weather == null ? 'events.edit.weather.empty' : ( event.weather.ignored == true ? 'sites.weather.ignored' : 'events.edit.weather.date_required')) : 'sites.weather.ignored') | translate }}
                      </div>
                      <div *ngIf="weatherApiInvalidResponse" [class]="isOnline ? (event.weather == null ? '' : ( event.weather.ignored == true ? 'weather-ignored' : 'weather-pending')) : 'weather-ignored'">
                        {{ 'events.edit.weather.error' | translate }}
                      </div>
                    </ion-col>
                  </ion-item>
                </ng-template>
              </ion-row>
              <ion-row>
                <ion-col>
                  <div *ngIf="!isOnline">
                    <app-offline-weather-error>
                    </app-offline-weather-error>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>

            <!-- error message handler -->
            <ion-item lines="none" class="invalid" *ngIf="getControlErrors('weather') != null">
              <ion-label color="danger">
                <small>{{ getControlErrors('weather') }}</small>
              </ion-label>
            </ion-item>
          </ion-card-content>
        </ion-card>

        <!-- START CUSTOM FORM LAYOUT -->
        <div *ngIf="currentCustomEventForm && currentCustomEventForm.formLayout.length > 0" class="custom-form">
          <!-- START CUSTOM FORM SECTION -->
          <div *ngFor="let section of currentCustomEventForm.formLayout" class="custom-form-section">
            <ion-card>
              <ion-card-content>
                <!-- START SECTION TITLE -->
                <ion-item-divider>
                  <ion-label>
                    <b class="item-headings">{{section.sectionTitle}}</b>
                  </ion-label>
                </ion-item-divider>
                <!-- END SECTION TITLE -->
                <div *ngFor="let field of currentCustomEventForm.customFields">
                  <div *ngFor="let fieldId of section.sectionFields">
                    <div *ngIf="field.id === fieldId">
                      <!-- START LEGACY FIELD -->
                      <div *ngIf="(field.fieldType.value === legacyFieldStringValue) && field.legacyFieldValue" class="custom-form-field-mobile">
                        <!-- START LEGACY FIELD TITLE -->
                        <h6 class="field-title">
                          {{field.title}}
                        </h6>
                        <!-- END LEGACY FIELD TITLE -->
                        <!-- START CONTRACTOR LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.contractor">
                          <app-event-contractor [customField]="field" [parentFormGroup]="formGroup" [contractors]="contractors" [isEdited]="isEdited" [contractorsLoaded]="contractorsLoaded" [deviceIsMobile]="true"></app-event-contractor>
                        </div>
                        <!-- END CONTRACTOR LEGACY FIELD -->

                        <!-- START LOCATION LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.location">
                          <app-event-location [customField]="field" [parentFormGroup]="formGroup" [locations]="locations" [isEdited]="isEdited" [locationsLoaded]="locationsLoaded" [deviceIsMobile]="true"></app-event-location>
                        </div>
                        <!-- END LOCATION LEGACY FIELD -->

                        <!-- START PHOTOS LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.photos">
                          <app-event-photos #eventPhotosComponent [customField]="field" [parentFormGroup]="formGroup" [isEdited]="isEdited" [isViewReady]="isViewReady" [event]="event" [deviceIsMobile]="true" [eventId]="itemId"></app-event-photos>
                        </div>
                        <!-- END PHOTOS LEGACY FIELD -->

                        <!-- START TAG LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.tags">
                          <app-event-tag [customField]="field" [parentFormGroup]="formGroup" [tags]="tags" [isEdited]="isEdited" [tagsLoaded]="tagsLoaded" [deviceIsMobile]="true"></app-event-tag>
                        </div>
                        <!-- END TAG LEGACY FIELD -->

                        <!-- START PEOPLE LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.people">
                          <app-event-people [customField]="field" [parentFormGroup]="formGroup" [labours]="labours" [isEdited]="isEdited" [laboursLoaded]="laboursLoaded" [deviceIsMobile]="true"></app-event-people>
                          <ion-text color="danger" *ngIf="getControlErrors('labours') != null">
                            <small>{{ getControlErrors('labours') }}</small>
                          </ion-text>
                        </div>
                        <!-- END PEOPLE LEGACY FIELD -->

                        <!-- START EQUIPMENT LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.equipment">
                          <app-event-equipment [customField]="field" [parentFormGroup]="formGroup" [plants]="plants" [isEdited]="isEdited" [plantsLoaded]="plantsLoaded" [deviceIsMobile]="true"></app-event-equipment>
                          <ion-text color="danger" *ngIf="getControlErrors('plants') != null">
                            <small>{{ getControlErrors('plants') }}</small>
                          </ion-text>
                        </div>
                        <!-- END EQUIPMENT LEGACY FIELD -->

                        <!-- START MATERIAL LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.materials">
                          <app-event-material [customField]="field" [parentFormGroup]="formGroup" [materials]="materials" [isEdited]="isEdited" [materialsLoaded]="materialsLoaded" [deviceIsMobile]="true"></app-event-material>
                          <ion-text color="danger" *ngIf="getControlErrors('materials') != null">
                            <small>{{ getControlErrors('materials') }}</small>
                          </ion-text>
                        </div>
                        <!-- END MATERIAL LEGACY FIELD -->

                        <!-- START NOTES LEGACY FIELD -->
                        <div *ngIf="field.legacyFieldValue.value === legacyFieldType.notes">
                          <app-event-notes [customField]="field" [parentFormGroup]="formGroup" [isEdited]="isEdited" [deviceIsMobile]="true"></app-event-notes>
                        </div>
                        <!-- END CONTRACTOR LEGACY FIELD -->
                      </div>
                      <!-- END LEGACY FIELD -->

                      <!-- START CUSTOM FIELD -->
                      <div *ngIf="field.fieldType.value !== legacyFieldStringValue">
                        <app-event-custom-field [customField]="field" [parentFormGroup]="formGroup" [deviceIsMobile]="true" [isEdited]="isEdited" [event]="event">
                        </app-event-custom-field>
                      </div>
                      <!-- END CUSTOM FIELD -->
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>

          </div>
          <!-- END CUSTOM FORM SECTION -->
        </div>
        <!-- END CUSTOM FORM LAYOUT -->

        <ion-card *ngIf="!isNewItem && event && (event.status === EventStatus.REJECTED || event.status === EventStatus.APPROVED)">
          <ion-card-content>
            <ion-item-divider (click)="isCollapsedApprovalComment = !isCollapsedApprovalComment">
              <ion-label [attr.aria-expanded]="!isCollapsedApprovalComment" aria-controls="collapseApprovalComment">
                <b class="item-headings">{{'events.section_title.comments' | translate}}</b>
              </ion-label>
              <ion-icon slot="end" [attr.name]="isCollapsedApprovalComment ? 'chevron-down' : 'chevron-up'"></ion-icon>
            </ion-item-divider>

            <div id="collapseApprovalComment" [ngbCollapse]="isCollapsedApprovalComment">
              <ion-textarea readOnly
              formControlName="approvalComment"
              rows="8"
              auto-grow = "true"
              [placeholder]="'mobile-placeholder-description' | translate">
              </ion-textarea>
            </div>
          </ion-card-content>
        </ion-card>

      </form>
    </ion-list>
  </div>
</div>
