<div class="custom-field-web" *ngIf="!deviceIsMobile" [class.hide-fields]="!isEdited && isEmpty('plants')">
  <app-select-multiple-assets [parentFormGroup]="parentFormGroup" controlName="plants" [items]="plants"
  [isReadOnly]="!isEdited" [isLoading]="!plantsLoaded" [hasDuration]="true" class="assets-template"
  placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.plant.label' | translate }}">
  </app-select-multiple-assets>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item
    class="card-items"
    lines="none"
    (click)="openItemSelectorPopup(plants, parentFormGroup.value.plants, 'plants', 'multiple_with_quantity_and_duration')">
    <ion-input
      type="text"
      readonly
      [placeholder]="'mobile-placeholder-plants' | translate"
      *ngIf="parentFormGroup.value.plants.length === 0">
    </ion-input>
    <ion-icon name="equipment" color="primary" slot="start"></ion-icon>
    <span class="assets-container" *ngIf="parentFormGroup.value.plants.length > 0">
        {{ parentFormGroup.value.plants | listToLabelList | join }}
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <ion-grid *ngIf="plants.length > 0">
    <ion-row>
      <ion-col>
        <header class="label">{{customField.title}}</header>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let equipment of plants" class="people-row">
      <ion-col>
        <div class="people-pill-labour">
          <ion-grid>
            <ion-row>
              <ion-col>
                <p>{{equipment.name}}</p>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span>
                  {{ 'plugins.select.qty' | translate }} {{equipment.amount}}
                </span>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span>
                  <ion-icon mode="md" name="time-outline"></ion-icon> {{timeConvert(equipment.duration)}}
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

