import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import {
  MobileGalleryPopupComponent,
} from '../../../../home/site/event/event-form/mobile-gallery-popup/mobile-gallery-popup.component';
import { Attachment } from '../../../../models/attachment';
import { AttachmentService } from '../../../../services/attachment.service';
import { DeviceService } from '../../../../services/device.service';
import { ImageResizerService } from '../../../../services/image/image-resizer.service';
import { ToasterService } from '../../../../services/toaster.service';
import { Logger } from '../../../../services/logger';
import { FileFormatError } from '../../../../models/errors/file/file-format-error';
import { FileSizeError } from '../../../../models/errors/file/file-size-error';
import { StripeService } from '@services/stripe.service';
import { UpgradeService } from '../../../../subscription/upgrade.service';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageEditorComponent } from 'app/home/site/event/event-form/mobile-gallery-popup/image-editor/image-editor.component';
import { SecureImageSrcPipe } from 'app/pipes/secure-image.pipe';
import { SpinnerService } from '@services/spinner.service';
import { NetworkStatus } from '@models/synchronization/network-status';
import { Photo, GalleryPhoto } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { FileConversionService } from '@services/file-conversion.service';
import Viewer from 'viewerjs';
import { SharedDataService } from '@services/shared-data.service';
import { TaskService } from '@services/task.service';

@Component({
  selector: 'app-images-input',
  templateUrl: './images-input.component.html',
  styleUrls: ['./images-input.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImagesInputComponent),
    multi: true
  }]
})
export class ImagesInputComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() readOnly = false;
  @Input() spaceId = null;
  @Input() siteId = null;
  @Input() eventId = null;
  @Input() appType = 'diary';
  @Input() isNewEvent: Boolean = false;
  @Input() eventTitle = null;

  isDownloadButtonAdded: boolean = false;

  public viewerOptions: Viewer.Options = {
    title: false,
    toolbar: {
      zoomIn: false,
      zoomOut: false,
      oneToOne: false,
      rotateLeft: false,
      rotateRight: false,
      prev: true,
      reset: false,
      play: false,
      next: true,
      flipHorizontal: false,
      flipVertical: false,
    },
    className: 'ngx-image-viewing-webapp',
    transition: false,
    viewed: async (image) => {
      let imageId = this.attachments[image.detail.index].id;
      this.loadImageByImageId(imageId);
      this.addDownloadButtonToToolbar();
    },
  };

  private onChangeCallback: (any) => void;

  private storedImages: {[attachmentId: string]: string} = {};

  public attachments: Attachment[];
  public currentAttachmentId: string;
  public viewer: Viewer = null;

  constructor(
    public deviceService: DeviceService,
    private toasterService: ToasterService,
    private attachmentService: AttachmentService,
    private modalController: ModalController,
    private imageResizerService: ImageResizerService,
    public stripeService: StripeService,
    private upgradeService : UpgradeService,
    private logger: NGXLogger,
    private modalService: NgbModal,
    private secureImageSrcPipe:SecureImageSrcPipe,
    private spinnerService: SpinnerService,
    private fileConversion: FileConversionService
  ) {
  }

  ngOnDestroy(): void {
    if(this.viewer) {
      this.viewer.destroy();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let imageViewerElement = document.querySelector(".ngx-viewer") as HTMLElement;
    if(imageViewerElement) {
      this.viewer = new Viewer(imageViewerElement, this.viewerOptions);
    }
  }

  addDownloadButtonToToolbar() {
    if (!this.isDownloadButtonAdded) {
      const viewerContainer = document.querySelector('.viewer-container');
      if (!viewerContainer) {
        return;
      }

      const downloadButton = document.createElement('div');
      downloadButton.className = 'viewer-download viewer-button image-download-button';
      downloadButton.setAttribute('role', 'button');
      downloadButton.setAttribute('tabindex', '0');
      const ionIcon = document.createElement('ion-icon');
      ionIcon.setAttribute('name', 'download-outline');
      downloadButton.appendChild(ionIcon);
      
      ionIcon.style.marginTop = '28px';
      const title = this.eventTitle;
      downloadButton.onclick = () => {
        const imgElement = viewerContainer.querySelector('.viewer-move');
        if (!(imgElement instanceof HTMLImageElement)) {
          this.logger.error('No image found for downloading in ImageInputComponent.');
          return;
        }
        const imgUrl = imgElement.src;

        fetch(imgUrl)
          .then(response => response.blob())
          .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;
            downloadLink.download = title;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            window.URL.revokeObjectURL(blobUrl);
          })
      };

      viewerContainer.appendChild(downloadButton);
      this.isDownloadButtonAdded = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.attachments = obj;
  }

  removeAttachment(attachment: Attachment): void {
    if (attachment) {
      this.attachments = this.attachments.filter(item => item.id !== attachment.id);
      if (attachment.id in this.storedImages) {
        delete (this.storedImages[attachment.id]);
      }
      if (this.onChangeCallback) {
        this.onChangeCallback(this.attachments);
      }
      // The attachments are not being loaded correctly after updating the viewer
      // It takes time to load attachments, to fix this issue setTimeout is used
      setTimeout(() => {
        if(this.viewer) {
          this.viewer.update();
        }
      });
    }
  }

  /**
   * Convert uploaded images into attachments
   * @param images an array of uploaded images
   */
  public async uploadImages(images: File[]) {
    try {
      await this.convertUploadedImagesIntoAttachments(images);
    } catch (error) {
      this.logger.error("Error while converting images into attachments", error);
      switch (error.constructor) {
        default:
          this.toasterService.showErrorToaster('events.detail.notify.error.img_upload');
          break;
        case FileFormatError:
          this.toasterService.showErrorToaster('events.detail.notify.error.type.description');
          break;
        case FileSizeError:
          this.toasterService.showErrorToaster('events.detail.notify.error.size.description');
          break;
      }
    }
  }

  /**
   * Browse into selected images and convert them into Attachement
   * @param images selected images
   */
  private async convertUploadedImagesIntoAttachments(images: File[]) {
    if (!images) {
      this.logger.error('Trying to convert an empty array of images into attachments');
      throw new Error('try to convert empty array of images into attachments');
    }

    for (const file of images) {
      const blob = await this.imageResizerService.resize(file);
      const attachment = new Attachment();
      this.attachments.push(attachment);
      if (this.onChangeCallback) {
        this.onChangeCallback(this.attachments);
      }
      this.storedImages[attachment.id] = null;
      this.storeImage(blob, attachment);
    }
    this.logger.debug('Images are converted into Attachments');
    // The attachments are not being loaded correctly after updating the viewer
    // It takes time to load attachments, to fix this issue setTimeout is used
    setTimeout(() => {
      if(this.viewer) {
        this.viewer.update();
      }
    });
  }

  //TODO: Resize the images to follow the same criteria as used before with the cordova integration
  /**
   * Convert capacitor photo object into attachment and add it to the stored attachments array
   * @param photo
   */
  public async convertPhotoIntoAttachment(photo: Photo): Promise<void> {
    if (!photo) {
      this.logger.error('Trying to convert an empty photo into attachment');
      throw new Error('Trying to convert an empty photo into attachment');
    }

    const attachment = new Attachment();
    this.attachments.push(attachment);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.attachments);
    }
    const blob = await this.imageResizerService.resize(photo.dataUrl, photo.format);
    this.storedImages[attachment.id] = null;
    this.storeImage(blob, attachment);
    this.logger.debug('Photo is converted into an Attachment');
  }

  //TODO: Resize the images to follow the same criteria as used before with the cordova integration
  /**
   * Convert capacitor galley photos object into attachments and add them to the stored attachments array
   * @param photo
   */
  public async convertGalleryPhotosIntoAttachments(galleryPhotos: GalleryPhoto[]): Promise<void> {
    if (!galleryPhotos || galleryPhotos.length === 0) {
      this.logger.error('Trying to convert an empty gallery photo array into attachments');
      throw new Error('Trying to convert an empty gallery photo array into attachments');
    }
    for(let photo of galleryPhotos) {
      {
        const attachment = new Attachment();
        this.attachments.push(attachment);
        if (this.onChangeCallback) {
          this.onChangeCallback(this.attachments);
        }
        //TODO: Test Filesystem permissions check
        const photoData = await Filesystem.readFile({path: photo.path});
        const photoBase64Data = `data:image/jpeg;base64,${photoData.data}`;
        const blob = await this.imageResizerService.resize(photoBase64Data);
        this.storedImages[attachment.id] = null;
        this.storeImage(blob, attachment);
      }
    }
    this.logger.debug('Gallery photos are converted into Attachments');
  }

  /**
   * Replace current image with edited image
   * @param file
   */
  replaceEditedImage(file: string , attachmentId: string): void {
    // adding edited attachment
    const attachment = new Attachment();
    this.attachments.push(attachment);
    this.storedImages[attachment.id] = file;

    // removing old attachment
    const attachmentToRemove = new Attachment();
    attachmentToRemove.id = attachmentId;
    this.removeAttachment(attachmentToRemove);
  }

  /**
   * Store given image
   * @param file
   */
  storeImage(file: Blob, attachment: Attachment): void {
    this.attachmentService.getPictureUrlFromFile(file)
      .then(value => {
        if ((attachment.id in this.storedImages)) {
          this.storedImages[attachment.id] = value;
        }
      });
  }

  getPictureUrl(attachment: Attachment, isThumbnail: boolean = true): string {
    if (attachment.id in this.storedImages) {
      if (this.storedImages[attachment.id]) {
        return this.storedImages[attachment.id];
      } else {
        return AttachmentService.DEFAULT_ATTACHMENT_URL;
      }
    } else {
      return this.attachmentService.getPictureUrl(this.spaceId, this.siteId, this.eventId, attachment.id, isThumbnail);
    }
  }

  getPictureUrlById(attachmentId, isThumbnail: boolean = true): string {
    if (attachmentId in this.storedImages) {
      if (this.storedImages[attachmentId]) {
        return this.storedImages[attachmentId];
      } else {
        return AttachmentService.DEFAULT_ATTACHMENT_URL;
      }
    } else {
      return this.attachmentService.getPictureUrl(this.spaceId, this.siteId, this.eventId, attachmentId, isThumbnail);
    }
  }

  isNewAttachment(attachment: Attachment): boolean {
    return attachment && attachment.id in this.storedImages;
  }

  getAttachmentsToUpload(): {[attachmentId: string]: string} {
    return this.storedImages;
  }

  clearAllAttachmentsToUpload() {
    delete(this.storedImages);
    this.storedImages = {};
  }

  async openMobileGalleryPopup() {
    const modal = await this.modalController.create({
      component: MobileGalleryPopupComponent,
      componentProps: {
        attachments: this.attachments,
        imagesInputComponent: this,
        readonly: this.readOnly,
        isNewEvent: this.isNewEvent
      },
    });
    return await modal.present();
  }

  public async openWebappImageEditorPopup(image: string | ArrayBuffer) {
    const modalRef = this.modalService.open(ImageEditorComponent, {
      centered: true,
      size: 'lg',
      backdrop:'static'
    },);
    modalRef.componentInstance.image = image;

    modalRef.componentInstance.editedImage.subscribe((image: string | null) => {
      if(image) {
        this.replaceEditedImage(image, this.currentAttachmentId);
      }
      modalRef.close();
    })
  }

  public async editImage(attachment: Attachment) {
    const imageURL = this.getPictureUrl(attachment, false);
    this.currentAttachmentId = attachment.id;
    const dataUrl = await this.convertImageUrlToDataUrl(imageURL);
    this.openWebappImageEditorPopup(dataUrl);
  }

  public convertImageUrlToDataUrl(imageURL: string): Promise<string> {
    return new Promise(async (resolve) => {
      const blob = await fetch(imageURL).then(r => r.blob());
      const dataUrl = await new Promise(resolve => {
        const reader = this.fileConversion.getFileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      resolve(dataUrl as string);
    })
  }

  annoyUser(): void {
    this.upgradeService.annoyUser('upgrade.feature.pictures');
  }

// load full resolution image
  async loadImageByImageId(imageId: string): Promise<void> {
    if(!(await this.attachmentService.isAttachmentLocallyStoredOrPresentInQueue(imageId))) {
      this.spinnerService.activate('rotating');
      let imageSrc = this.getPictureUrlById(imageId, false);
      if (NetworkStatus.isOnline) {
        this.secureImageSrcPipe.transformToBlobUrl(imageSrc).toPromise()
        .then((url) => {
          let newViewingImage: any = document.getElementsByClassName('viewer-move');
          newViewingImage[0].classList.add('ngx-viewer-move-webapp');
          newViewingImage[0].src = url;
          this.spinnerService.deactivate();
        })
        .catch((error) => {
          this.logger.error("Error Loading full Image", error);
          this.spinnerService.deactivate();
        });
      }
      else {
        this.spinnerService.deactivate();
      }
    }
  }
}
