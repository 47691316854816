export enum NOTIFICATION_TYPE {
  EVENT_REJECTION = 'EVENT_REJECTION',
  TASK_ASSIGNMENT = 'TASK_ASSIGNMENT',
  TASK_COMPLETION = 'TASK_COMPLETION',
  TAG_NOTIFICATION = 'TAG_NOTIFICATION',
  REMOVED_FROM_SPACE = 'REMOVED_FROM_SPACE',
  ADDED_TO_SPACE = 'ADDED_TO_SPACE',
  USER_ACCOUNT_DELETED = 'USER_ACCOUNT_DELETED',
}

export enum NOTIFICATION_TYPE_VERSION {
  EVENT_REJECTION_V1 = 1,
  TASK_ASSIGNMENT_V1 = 1,
  TASK_COMPLETION_V1 = 1,
  TASK_ASSIGNMENT_V2 = 2,
  TASK_COMPLETION_V2 = 2,
  TAG_NOTIFICATION_V1 = 1,
  REMOVED_FROM_SPACE_V1 = 1,
  ADDED_TO_SPACE_V1 = 1,
  USER_ACCOUNT_DELETED = 1
}

export enum NOTIFICATION_TYPE_LABEL {
  EVENT_REJECTION = 'notification.configuration.type.event_rejection.label',
  TASK_ASSIGNMENT = 'notification.configuration.type.task_assignment.label',
  TASK_COMPLETION = 'notification.configuration.type.task_completion.label',
  TAG_NOTIFICATION = 'notification.configuration.type.tag_notification.label',
}
