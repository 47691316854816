import { ModelElement } from './model-element';
import { Identifiable } from './identifiable';

export class Attachment implements Identifiable {
  id: string;

  constructor(
    id: string = ModelElement.generateId()
  ) {
    this.id = id;
  }
}
