import { AfterViewChecked, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '@services/asset.service';
import { AttachmentService } from '@services/attachment.service';
import { ContractorService } from '@services/contractor.service';
import { DeviceService } from '@services/device.service';
import { EventEmailService } from '@services/emails/event-email.service';
import { EventService } from '@services/event.service';
import { FileTransfertService } from '@services/file-transfert.service';
import { CustomDatepickerI18nService, NgbDateParserFormatterI18N } from '@services/i18n/date-internationalization.service';
import { IntercomService } from '@services/intercom.service';
import { SessionService } from '@services/session.service';
import { Coordinates, CoordinatesService } from '@services/shared/coordinates.service';
import { SiteService } from '@services/site.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { WeatherCodeDetails, WeatherService } from '@services/weather.service';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { SelectTaskComponent } from 'app/form/controls/select-task/select-task.component';
import { BackupService, BACKUP_TYPES } from '@services/backup/backup.service';
import { DatabaseService } from '@services/shared/database.service';
import { LocationService } from '@services/location.service';
import { ExportService } from '@services/export.service';
import { SpinnerService } from '@services/spinner.service';
import { NGXLogger } from 'ngx-logger';
import { CustomEventFormService } from '@services/custom-event-form.service';
import { Event, EventStatus } from '@models/event';
import { CustomEventField, CustomEventFieldTypeValue, CustomEventLegacyFieldValue } from '@models/custom-event-field';
import { CustomEventFormSection } from '@models/custom-event-form-layout';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { SharedDataService } from '@services/shared-data.service';
import { maxDurationValidator } from '@validators/max-duration.validator';
import { positiveDurationValidator } from '@validators/positive-duration.validator';
import { notBlankValidator } from '@validators/not-blank.validator';
import { eventNotInTheFutureValidator } from '@validators/event-not-in-the-future.validator';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { Tag } from '@models/tag';
import { Location } from '@models/location';
import { Contractor } from '@models/contractor';
import { CustomEventForm } from '@models/custom-event-form';
import { EventAsset } from '@models/event-asset';
import { EventContractor } from '@models/event-contractor';
import { EventTag } from '@models/event-tag';
import { User } from '@models/user';
import { Weather } from '@models/weather';
import { EventTaskAdapter } from '@models/event-task-adapter';
import { Author } from '@models/author';
import { EventPhotosComponent } from './custom-event-form-components/event-photos/event-photos.component';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { AssetCategory } from '@models/asset';
import { EventApproveRejectConfigurationPopupComponent } from '../event-list/event-approve-reject-configuration-popup/event-approve-reject-configuration-popup.component';
import { NetworkStatus } from '@models/synchronization/network-status';
import { PdfExportConfigurationPopupComponent } from '../event-list/pdf-export-configuration-popup/pdf-export-configuration-popup.component';
import { SyncStatusService } from '@services/synchronization/sync-status.service';
import { HttpStatus } from '@constants/http/http-status';
import { ArchiveSitesService } from '@services/archive-sites.service';
import { SyncExceptionType } from '@services/synchronization/sync-exception';
import { numberNotPositiveIntegerValidator } from '@validators/number-not-positive-integer.validator';
import { materialAssetNotPositiveNumberValidator } from '@validators/material-asset-not-positive-number.validator';
import { UserRole } from '@services/user-rights/user-rights-dao.service';

@Component({
  selector: 'app-event-form-webapp',
  templateUrl: './event-form-webapp.component.html',
  styleUrls: [
    '../../../../form/abstract-form-component-webapp.sass',
    './event-form-webapp.component.scss',
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter,
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterI18N },
  ],
})
export class EventFormWebappComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewChecked {
  @Output() editForm = new EventEmitter<boolean>();

  protected ERROR_TRANSLATE_KEYS_PREFIX: string = 'event-and-task';

  @ViewChild('selectTaskTemplate')
  selectTaskTemplate: SelectTaskComponent;

  @ViewChild('eventPhotosComponent')
  public eventPhotosComponent: EventPhotosComponent;

  resetWeatherEmitter: Subject<Coordinates> = new Subject<Coordinates>();

  public isCollapsedActivityInformation = false;
  public isCollapsedResources = false;
  public isCollapsedOtherDetails = false;
  public isCollapsedNotes = false;
  public isCollapsedApprovalComment = false;

  public event: Event;
  coordinates: Coordinates;
  formGroup: FormGroup;
  isCustomEventFormNotAvailable: boolean = false;
  defaultEndDate: string;
  weatherCodeDetails: WeatherCodeDetails = null;
  isPreviewingWeather: boolean;
  tagsLoaded: boolean = false;
  locationsLoaded: boolean = false;
  contractorsLoaded: boolean = false;
  plantsLoaded: boolean = false;
  laboursLoaded: boolean = false;
  materialsLoaded: boolean = false;
  isEdited: boolean = false;
  isNewItem: boolean = false;
  canEdit: boolean = false;
  isViewReady: boolean = false;
  invalidFormSubmitted: boolean = false;
  canApprove: boolean = false;
  canReject: boolean = false;
  canDelete: boolean = false;
  taskPlannedQuantity: number = null;
  isEventBeingFetched: boolean = false;
  statusToSave: EventStatus = EventStatus.DRAFT;
  userHasAppAccess: boolean = false;
  isArchivedSite: boolean = false;
  isWeatherPendingProp: boolean = false;
  isGoogleMapOpened: boolean = false;
  isUserAdmin: boolean = false;
  adminBaseUrl: string = window.location.href.split('/space/')[0] + '/space/' + this.sharedDataService.currentSpaceId + '/event-form';

  public legacyFieldStringValue = CustomEventField.CUSTOMEVENTFIELDTYPE[2].value;
  legacyFieldType = {
    contractor: CustomEventLegacyFieldValue.CONTRACTORS,
    location: CustomEventLegacyFieldValue.LOCATION,
    photos: CustomEventLegacyFieldValue.PHOTOS,
    tags: CustomEventLegacyFieldValue.TAGS,
    people: CustomEventLegacyFieldValue.PEOPLE,
    equipment: CustomEventLegacyFieldValue.EQUIPMENT,
    materials: CustomEventLegacyFieldValue.MATERIALS,
    notes: CustomEventLegacyFieldValue.NOTES,
  }
  currentCustomEventForm: CustomEventForm;
  readonly EventStatus = EventStatus;
  weatherApiInvalidResponse: boolean = false;
  isWeatherDataLoading: boolean = false;

  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  get customFields() {
    return this.formGroup.controls["customFields"] as FormArray;
  }

  //#region Tasks
  _tasks: EventTaskAdapter[] = [];

  get tasks(): EventTaskAdapter[] {
    return this._tasks;
  }
  set tasks(tasks: EventTaskAdapter[]) {
    this._tasks = tasks;
    if (this.event) {
      this.updateSelectedTasks(tasks, this.event);
    }
  }
  //#endregion

  _tags: Tag[] = [];
  get tags(): Tag[]{
    return this._tags.sort(Tag.compareByName);
  }
  set tags(tags: Tag[]) {
    this._tags = tags.sort(Tag.compareByName);
    this.updateSelectedTagOrContractor(
      this.formGroup.controls['tags'],
      tags,
      this.event ? this.event.tags : null
    );
  }

  _contractors: Contractor[]= [];
  get contractors(): Contractor[]{
    return this._contractors.sort(Contractor.compareByName);
  }
  set contractors(contractors: Contractor[]) {
    this._contractors = contractors.sort(Contractor.compareByName);
    this.updateSelectedTagOrContractor(
      this.formGroup.controls['contractors'],
      contractors,
      this.event ? this.event.contractors : null
    );
  }

  _labours: EventAsset[] = [];
  get labours(): EventAsset[] {
    return this._labours;
  }
  set labours(labours: EventAsset[]) {
    this._labours = labours;
    this.updateSelectedAsset(this.formGroup.controls['labours'], labours);
  }

  _plants: EventAsset[] = [];
  get plants(): EventAsset[] {
    return this._plants.sort(EventAsset.compareByName);
  }
  set plants(plants: EventAsset[]) {
    this._plants = plants.sort(EventAsset.compareByName);
    this.updateSelectedAsset(this.formGroup.controls['plants'], plants);
  }

  _materials: EventAsset[] = [];
  get materials(): EventAsset[] {
    return this._materials.sort(EventAsset.compareByName);
  }
  set materials(materials: EventAsset[]) {
    this._materials = materials.sort(EventAsset.compareByName);
    this.updateSelectedAsset(this.formGroup.controls['materials'], materials);
  }

  _locations: Location[] = [];
  get locations(): Location[] {
    return this._locations.sort(Location.compareByName);
  }
  set locations(locations: Location[]) {
    this._locations = locations.sort(Location.compareByName);
    this.updateSelectedLocation(
      this.formGroup.controls['location'],
      locations,
      this.event ? this.event.locationObject : null
    );
  }

  private updateSelectedLocation(
    control: AbstractControl,
    itemList: Location[],
    eventItem: Location
  ): void {
    let newValue: Location;
    if (control.dirty) {
      newValue = itemList.find(item =>
        control.value.find(selectedItem => item.id === selectedItem.id)
      );
    } else if (this.event && eventItem) {
      newValue = itemList.find(item =>
        item.id === eventItem.id
      );
    }
    control.setValue(newValue);
  }

  private updateSelectedTagOrContractor(
    control: AbstractControl,
    itemList: (Contractor | Tag)[],
    eventItems: (EventContractor | EventTag)[]
  ): void {
    let newValue = [];
    if (control.dirty) {
      newValue = itemList.filter(item =>
        control.value.some(selectedItem => item.id === selectedItem.id)
      );
    } else if (this.event) {
      newValue = itemList.filter(item =>
        eventItems.some((eventItem: any) =>
          item.id === (eventItem.contractorId ? eventItem.contractorId : eventItem.tagId)
        )
      );
    }
    control.setValue(newValue);
  }

  // By design, event assets initally contain only their ID. This completes the
  // asset object with the name attribute
  private completeAssetName(asset: EventAsset, assetList: EventAsset[]): EventAsset {
    const referenceAsset = assetList.find(reference => reference.assetId === asset.assetId);
    if (referenceAsset) {
      asset.name = referenceAsset.name;
      return asset;
    }
    return null;
  }

  private updateSelectedAsset(control: AbstractControl, assetList: EventAsset[]): void {
    let assets = [];
    if (control.dirty) {
      assets = control.value;
    } else if (this.event) {
      assets = this.event.assets;
    }
    control.setValue(
      assets
        .map(selectedEventAsset => this.completeAssetName(selectedEventAsset, assetList))
        .filter(selectedEventAsset => selectedEventAsset)
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(this.event && this.isEdited && this.formGroup && this.formGroup.dirty);
  }

  constructor(
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    protected toasterService: ToasterService,
    public deviceService: DeviceService,
    protected alertController: AlertController,
    protected sessionService: SessionService,
    protected userRightsService: UserRightsService,
    protected eventService: EventService,
    protected contractorService: ContractorService,
    protected siteService: SiteService,
    protected urlGiver: UrlGiverService,
    protected fileTransfertService: FileTransfertService,
    protected tagService: TagService,
    protected assetService: AssetService,
    protected locationService: LocationService,
    protected coordinatesService: CoordinatesService,
    protected taskService: TaskService,
    protected attachmentService: AttachmentService,
    protected weatherService: WeatherService,
    protected eventEmailService: EventEmailService,
    protected intercomService: IntercomService,
    protected formBuilder: FormBuilder,
    protected backupService: BackupService,
    protected databaseService: DatabaseService,
    protected exportService: ExportService,
    protected modalService: NgbModal,
    protected spinnerService: SpinnerService,
    protected logger: NGXLogger,
    protected customEventFormService: CustomEventFormService,
    protected userAppAccessService: UserAppAccessService,
    protected sharedDataService: SharedDataService,
    protected syncStatuService: SyncStatusService,
    protected archiveSitesService: ArchiveSitesService,
  ) {
    super();
    this.coordinates = new Coordinates();
    this.formGroup = this.formBuilder.group({
      'title': ['', [Validators.required, Validators.maxLength(255), Validators.minLength(3), notBlankValidator]],
      'startDatetime': [null, [Validators.required, eventNotInTheFutureValidator]],
      'endDatetime': [null],
      'location': [],
      'contractors': [[]],
      'eventTaskAdapter': [],
      'tags': [[]],
      'plants': [[], [numberNotPositiveIntegerValidator]],
      'materials': [[], [materialAssetNotPositiveNumberValidator]],
      'labours': [[], [numberNotPositiveIntegerValidator]],
      'description': [''],
      'approvalComment': [''],
      'attachments': [[]],
      'quantityDone': [],
      'quantityUnit': [],
      'customFields': this.formBuilder.array([]),
    }, {
      validator: [maxDurationValidator, positiveDurationValidator],
    });
    this.isCustomEventFormNotAvailable = false;
    this.userRightsService.watchCurrentRoles().pipe(
      takeUntil(this.destroy)
    ).subscribe(async (userRoles) => { this.isUserAdmin = userRoles[0] === UserRole.spaceAdmin });
  }

  ngOnInit(): void {
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.syncStatuService.waitForEndOfEventSubsetApiSync().then(() => {
        this.fetchSiteData();
        const eventId = this.route.snapshot.paramMap.get('eventId');
        const isFilteredEvent = this.route.snapshot.queryParamMap.get('isFilteredEvent');
        if(eventId && isFilteredEvent) {
          this.getEventData(eventId, true);
        } else if(eventId && eventId !== 'new') {
          this.getEventData(eventId, false);
        } else {
          this.getEventData(null, false);
        }
        this.formGroup.controls['endDatetime'].valueChanges.pipe(
          takeUntil(this.destroy)
        ).subscribe(() => {
          this.defaultEndDate = moment(this.formGroup.controls['startDatetime'].value).add(1, 'hours').toISOString(true);
          if (this.formGroup.controls['endDatetime'].dirty) {
            this.formGroup.controls['startDatetime'].updateValueAndValidity();
            this.formGroup.controls['startDatetime'].markAsDirty();
          }
        });
        this.formGroup.controls['startDatetime'].valueChanges.pipe(
          takeUntil(this.destroy)
        ).subscribe(() => {
          if(this.formGroup.controls['endDatetime'].value) {
            this.previewWeather();
          }
        });
        this.userAppAccessService.watchCurrentAppAccess().subscribe((userAppAccess) => {
          this.userHasAppAccess = this.userAppAccessService.userHasCorrectAppAccess();
        });

        // watch if current site is archived
        this.sharedDataService.watchSite.subscribe((site) => {
          if(site) {
            this.isArchivedSite = site.isArchived;
          }
        });
      });
    });
  }

  ngAfterViewChecked(): void {
    this.isViewReady = true;
  }

  getEventData(eventId: string, fetchFromServer: boolean): void {
    if(eventId && fetchFromServer) {
      this.spinnerService.activate('rotating');
      this.isEventBeingFetched = true;
      this.sharedDataService.getEventFromBackendById(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId, eventId).subscribe((event) => {
        let newEvent = new Event();
        Event.singleEventToModel(event, newEvent);
        this.event = newEvent;
        this.populateFormGroupData(false);
        this.isEventBeingFetched = false;
        this.spinnerService.deactivate();
      },(error) => {
        if (error.error.status === HttpStatus.NOT_FOUND) {
          this.toasterService.showWarningToaster("events.preview.event.not_found");
        }
        // on error go back to events list screen
        this.getBack();
      });
    } else if (eventId && !fetchFromServer) {
      let newEvent = this.sharedDataService.getEventById(eventId);
      if (newEvent && newEvent !== null) {
        let eventCopy = this.createEventCopy(newEvent);
        this.sortDataAlphabetically(eventCopy);
        this.event = eventCopy;
        this.populateFormGroupData(false);
      }
      else {
        // get event from backend if the event was not found in the initial 250 events array
        this.getEventData(eventId, true);
      }
    } else {
      let newEvent = new Event();
      this.event = newEvent;
      this.setEventWeather();
      this.isEdited = true;
      this.isNewItem = true;
      this.populateFormGroupData(true);
    }
  }

  setEventWeather(): void {
    this.event.startDatetime.setMinutes(this.getNearest15Mins(this.event.startDatetime));
    this.setDefaultWeather().then(weather => {
      this.event.weather = weather;
      this.renewWeatherForNewEvent();
    });
  }

  populateFormGroupData(isNewEvent: boolean): void {
    this.formGroup.reset();
    this.setItem(this.event);
    this.formGroup.markAsPristine();
    if (this.sharedDataService.hasSiteSelected) {
      this.canEdit = false;
      this.updateRights(this.sessionService.getCurrentUser(), this.event);
    }
    this.fetchAndPopulateWithEventCustomFormData(this.event, isNewEvent);
  }

  fetchSiteData(): void {
    this.sharedDataService.watchSiteTags.pipe(
      takeUntil(this.destroy),
    ).subscribe(tags => this.tags = tags);
    this.sharedDataService.watchSiteLocations.pipe(
      takeUntil(this.destroy),
    ).subscribe(locations => this.locations = locations);
    this.sharedDataService.watchSiteContractors.pipe(
      takeUntil(this.destroy),
    ).subscribe(contractors => this.contractors = contractors);
    this.sharedDataService.watchSiteAssets.pipe(
      takeUntil(this.destroy),
    ).subscribe((assets) => {
      const labours = assets.filter((_assets) => _assets.category === AssetCategory.LABOURS);
      const plants = assets.filter((_assets) => _assets.category === AssetCategory.EQUIPMENTS);
      const materials = assets.filter((_assets) => _assets.category === AssetCategory.MATERIALS);
      this.labours = this.assetService.convertAssetsToNamedEventAssets(labours);
      this.plants = this.assetService.convertAssetsToNamedEventAssets(plants);
      this.materials = this.assetService.convertAssetsToNamedEventAssets(materials);
      this.plantsLoaded = true;
      this.laboursLoaded = true;
      this.materialsLoaded = true;
    });
    this.tagsLoaded = true;
    this.contractorsLoaded = true;
    this.locationsLoaded = true;
  }

  protected fetchAndPopulateWithEventCustomFormData(event: Event, isNewEvent): void {
    if(isNewEvent) {
      const form = this.sharedDataService.getCurrentActiveCustomForm();
      if(form) {
        this.isCustomEventFormNotAvailable = false;
        this.currentCustomEventForm = form;
        if(this.currentCustomEventForm.customFields) {
          this.currentCustomEventForm.customFields.forEach((field) => {
            if(field.fieldType.value !== CustomEventFieldTypeValue.LEGACY) {
              const customFormField = this.formBuilder.group({
                id: [field.id],
                value: [null],
              });
              this.customFields.push(customFormField);
            }
          });
        }
      }
    } else {
      const eventForm = this.sharedDataService.getCustomFormById(event.formLayoutId);
      if(eventForm) {
        this.isCustomEventFormNotAvailable = false;
        this.customFields.clear();
        this.currentCustomEventForm = eventForm;
        if(this.currentCustomEventForm.customFields) {
          this.currentCustomEventForm.customFields.forEach((field) => {
            if(field.fieldType.value !== CustomEventFieldTypeValue.LEGACY) {
              const customFieldValue = event.customFieldValues.find(customField => customField.uuid === field.id);
              const customFormField = this.formBuilder.group({
                id: [field.id],
                value: [customFieldValue ? customFieldValue.value : ''],
              });
              this.customFields.push(customFormField);
            }
          });
        }
      }
    }
  }

  createEventCopy(event: Event): Event {
    const newEvent = Object.assign(new Event(), event);
    newEvent.assets = event.assets.map(eventAsset => Object.assign(new EventAsset(), eventAsset));
    newEvent.contractors = event.contractors.map(eventContractor => Object.assign(new EventContractor(), eventContractor));
    newEvent.tags = event.tags.map(eventTag => Object.assign(new EventTag(), eventTag));
    return newEvent;
  }

  sortDataAlphabetically(event: Event) {
    // The assets only contain IDs at this point. So they will have to be mapped and the names will have to be fetched.
    event.assets.map(value => (
      this.completeAssetName(value, [...this.labours, ...this.plants, ...this.materials])
    )).filter(value => value);

    event.assets.sort((n1, n2) => {
      if (n1.name > n2.name) {
        return 1;
      } else if (n1.name < n2.name) {
        return -1;
      } else if (n1.name === n2.name) {
        /* 
          If the names of the assets are same, then we'll have to compare the 
          amounts of both the assets and sort them accordingly
        */
        return n1.amount > n2.amount ? 1 : -1;
      } else {
        return 0;
      }
    })
    this.tagService.sortTagsAlphabetically(event.tags);
    this.contractorService.sortContractorsAlphabetically(event.contractors);
  }

  protected setItem(event: Event): void {
    if (event) {
      this.formGroup.controls['labours'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.labours)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['plants'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.plants)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['materials'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.materials)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['tags'].setValue(this.tags.filter(tag =>
        event.tags.some(eventTag => tag.id === eventTag.tagId))
      );

      this.formGroup.controls['contractors'].setValue(this.contractors.filter(contractor =>
        event.contractors.some(eventContractor => contractor.id === eventContractor.contractorId))
      );

      this.updateSelectedTasks(this.tasks, event);

      this.formGroup.controls['title'].setValue(event.title);
      if (event.endDatetime) {
        this.formGroup.controls['endDatetime'].setValue(new Date(event.endDatetime).toISOString());
      }

      // To calculate difference between startDate & endDate we need to set startDate seconds & milliseconds to 0
      const startDateTimeMoment = moment(event.startDatetime).seconds(0).milliseconds(0);
      this.formGroup.controls['startDatetime'].setValue(startDateTimeMoment.toISOString(true));

      if (event.locationObject) {
        this.formGroup.controls['location'].setValue(this.locations.find(location => location.id === event.locationObject.id));
      }
      this.formGroup.controls['description'].setValue(event.description);
      this.formGroup.controls['approvalComment'].setValue(event.approvalComment);
      this.formGroup.controls['attachments'].setValue(event.attachments);
      this.defaultEndDate = moment(this.formGroup.controls['startDatetime'].value).add(1, 'hours').toISOString(true);

      this.showWeatherIfAvailable(event.weather);
    }
  }

  protected renewWeatherForNewEvent(): void {
    if (this.event && this.event.weather) {
      this.resetWeatherEmitter.next(new Coordinates(this.event.weather.latitude, this.event.weather.longitude));
    }
  }

  onClearTask() {
    this.formGroup.controls['eventTaskAdapter'].setValue(null);
  }

  onClearLocation() {
    this.formGroup.controls['location'].setValue(null);
  }

  isFieldInAGrid(field: CustomEventField): boolean {
    if((field.fieldType.value === this.legacyFieldStringValue) && field.legacyFieldValue) {
      if(field.legacyFieldValue.value === this.legacyFieldType.photos || field.legacyFieldValue.value === this.legacyFieldType.notes) {
        return true;
      }
    }
    return false;
  }

  isFieldCenterAligned(field: CustomEventField): boolean {
    if((field.fieldType.value === this.legacyFieldStringValue) && field.legacyFieldValue) {
      if(field.legacyFieldValue.value === this.legacyFieldType.tags) {
        return true;
      }
    }
    return false;
  }

  public isEmpty(controlName: string) {
    if (this.isViewReady && this.formGroup) {
      const control = this.formGroup.controls[controlName];
      return !control || !control.value || control.value.length === 0;
    }
    return true;
  }

  isSectionEmpty(section: CustomEventFormSection): boolean {
    let isSectionFieldsEmpty = true;
    for(let sectionField of section.sectionFields) {
      if(sectionField === this.legacyFieldType.contractor) {
        if(!this.isEmpty('contractors')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.equipment) {
        if(!this.isEmpty('plants')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.location) {
        if(!this.isEmpty('location')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.materials) {
        if(!this.isEmpty('materials')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.notes) {
        if(!this.isEmpty('description')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.people) {
        if(!this.isEmpty('labours')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.photos) {
        if(!this.isEmpty('attachments')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(sectionField === this.legacyFieldType.tags) {
        if(!this.isEmpty('tags')) {
          isSectionFieldsEmpty = false;
          break;
        }
      }
      if(this.event && this.event.customFieldValues) {
        this.event.customFieldValues.forEach((customField) => {
          if(customField.uuid === sectionField) {
            if(customField.value) {
              isSectionFieldsEmpty = false;
            }
          }
        });
      }
    }
    return isSectionFieldsEmpty;
  }

  isFieldEmpty(fieldId: string): boolean {
    let isFieldEmpty = true;
    if(fieldId === this.legacyFieldType.contractor) {
      if(!this.isEmpty('contractors')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.equipment) {
      if(!this.isEmpty('plants')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.location) {
      if(!this.isEmpty('location')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.materials) {
      if(!this.isEmpty('materials')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.notes) {
      if(!this.isEmpty('description')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.people) {
      if(!this.isEmpty('labours')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.photos) {
      if(!this.isEmpty('attachments')) {
        isFieldEmpty = false;
      }
    }
    if(fieldId === this.legacyFieldType.tags) {
      if(!this.isEmpty('tags')) {
        isFieldEmpty = false;
      }
    }
    if(this.customFields.value) {
      this.customFields.value.forEach((customField) => {
        if(customField.id === fieldId) {
          if(customField.value) {
            isFieldEmpty = false;
          }
        }
      })
    }
    return isFieldEmpty;
  }

  getNearest15Mins(date: Date): number {
    const minutes = date.getMinutes();
    const multiplier = (minutes % 15) <= 7 ? Math.floor(minutes / 15) : Math.floor(minutes / 15) + 1;
    return multiplier * 15;
  }

  private async setDefaultWeather(): Promise<Weather> {
    const weather = new Weather();
    const coordinates = await this.getSiteLocation();
    weather.latitude = coordinates.lat;
    weather.longitude = coordinates.lng;
    return weather;
  }

  private async getSiteLocation(): Promise<Coordinates> {
    try {
      return await this.coordinatesService.getLocationFromCurrentSite();
    } catch (ex) {
      return new Coordinates(null, null);
    }
  }

  previewWeather(
    date = this.formGroup.controls['startDatetime'].value,
  ): void {
    if (this.event) {
      const weather = this.event.weather;
      if (date && weather && weather.latitude && weather.longitude) {
        // If weather object is already filled and contains weather information, use existing info
        if (weather.humidity && weather.temperature && weather.windSpeed && this.formGroup.controls['startDatetime'].pristine) {
          this.weatherCodeDetails = this.weatherService.getWeather(weather);
        }
        else {
          this.isWeatherDataLoading = true;
          this.eventService.fetchWeather(weather, date).pipe(
            takeUntil(this.destroy)
          ).subscribe(_weather => {
            this.event.weather = _weather;
            this.weatherCodeDetails = this.weatherService.getWeather(this.event.weather);
            if(this.weatherCodeDetails) {
              this.weatherApiInvalidResponse = false;
            } else {
              this.weatherApiInvalidResponse = true;
            }
            this.isWeatherPendingProp = this.isWeatherPending();
            this.showWeatherIfAvailable(_weather);
            this.isWeatherDataLoading = false;
          });
        }
      }
    }
    this.isWeatherPendingProp = this.isWeatherPending();
  }

  private isWeatherPending(): boolean {
    return this.weatherCodeDetails === null || !!this.event &&
      !!this.event.weather &&
      !this.event.weather.ignored &&
      this.event.weather.humidity === null &&
      this.event.weather.temperature === null &&
      this.event.weather.windSpeed === null;
  }

  public onCoordinatesChanged(coordinates: Coordinates) {
    if (!this.event.weather || this.event.weather.longitude !== coordinates.lng || this.event.weather.latitude !== coordinates.lat) {
      this.event.weather = new Weather();
    }
    const weather = this.event.weather;
    weather.latitude = coordinates.lat;
    weather.longitude = coordinates.lng;
    if(this.event.startDatetime && this.event.endDatetime) {
      this.previewWeather();
    }
  }

  onWeatherActiveEvent(state: boolean): void {
    this.event.weather.ignored = !state;
  }

  public isDurationDisplayed(): boolean {
    return this.formGroup.value.startDatetime && this.formGroup.value.endDatetime
      && new Date(this.formGroup.value.startDatetime).getTime() < new Date(this.formGroup.value.endDatetime).getTime();
  }

  public getFormValidationStyles(controlsNameFilter: string[], errorsNameFilter: string[]): string {
    if (this.isViewReady && this.formGroup && this.formGroup.errors) {
      const errors = this.formGroup.errors;
      for (const error in errors) {
        if (errors[error] && (!errorsNameFilter || (errorsNameFilter && errorsNameFilter.includes(error)))) {
          let isAnyControlDirty = false;

          for (const controlName of controlsNameFilter) {
            if (this.formGroup.controls[controlName].dirty) { isAnyControlDirty = true; }
          }

          if (this.invalidFormSubmitted) {
            return 'is-invalid is-invalid-submitted';
          } else if (isAnyControlDirty) {
            return 'is-invalid';
          }
          else {
            return 'is-valid';
          }
        }
      }
    }
    return '';
  }

  public getValidationStyles(controlName: string): string {
    if (this.isViewReady && this.formGroup && this.formGroup.controls[controlName]) {
      if (this.invalidFormSubmitted && this.formGroup.controls[controlName].invalid) {
        return 'is-invalid is-invalid-submitted';
      } else if (this.formGroup.controls[controlName].dirty) {
        if (this.formGroup.controls[controlName].invalid) {
          return 'is-invalid';
        }
        if (this.formGroup.controls[controlName].valid) {
          return 'is-valid';
        }
      }
    }
    return '';
  }

  public getColorStyle(controlName: string): string {
    if (this.isViewReady && this.formGroup && this.formGroup.controls[controlName]) {
      if ((this.invalidFormSubmitted || this.formGroup.controls[controlName].dirty) && this.formGroup.controls[controlName].invalid) {
        return 'danger';
      }
      if (this.isEmpty(controlName)) {
        return 'medium';
      }
    }
    return 'primary';
  }

  private getControlErrorsText(controlName: string, errors: ValidationErrors): string | null {
    if (errors) {
      let errorText = '';
      for (const error in errors) {
        if (errors[error]) {
          this.translateService.get(`form.error.${this.ERROR_TRANSLATE_KEYS_PREFIX}.${controlName}.${error}`).subscribe(translation => {
            if (errorText !== '') {
              errorText += '\n';
            }
            errorText += translation;
          });
        }
      }
      return errorText;
    }
    return null;
  }

  public getControlErrors(controlName: string): string | null {
    if (this.isViewReady && this.formGroup) {
      const control = this.formGroup.controls[controlName];
      if (control && (control.dirty || this.invalidFormSubmitted) && control.invalid) {
        return this.getControlErrorsText(controlName, control.errors);
      }
    }
    return null;
  }

  private getFormErrorsText(errors: ValidationErrors, errorsNameFilter?: string[]): string | null {
    if (errors) {
      let errorText = '';
      for (const error in errors) {
        if (errors[error] && (!errorsNameFilter || (errorsNameFilter && errorsNameFilter.includes(error)))) {
          this.translateService.get(`form.error.${this.ERROR_TRANSLATE_KEYS_PREFIX}.${error}`).subscribe(translation => {
            if (errorText !== '') {
              errorText += '\n';
            }
            errorText += translation;
          });
        }
      }
      return errorText;
    }
    return null;
  }

  public getFormErrors(...errorsNameFilter: string[]) {
    if (this.isViewReady && this.formGroup) {
      return this.getFormErrorsText(this.formGroup.errors, errorsNameFilter);
    }
    return null;
  }

  public getFormAndControlsErrors() {
    if (this.isViewReady && this.formGroup) {
      let errorText = '';
      for (const controlName in this.formGroup.controls) {
        if (this.formGroup.controls[controlName]) {
          const controlErrors = this.getControlErrorsText(controlName, this.formGroup.controls[controlName].errors);
          if (controlErrors !== null) {
            errorText += (errorText === '') ? controlErrors : '\n' + controlErrors;
          }
        }
      }
      const formErrors = this.getFormErrorsText(this.formGroup.errors);
      if (formErrors !== null) {
        errorText += (errorText === '') ? formErrors : '\n' + formErrors;
      }
      return errorText;
    }
    return null;
  }

  protected updateRights(user: User, event: Event): void {
    if (user && event) {
      this.canApprove = false;
      this.canReject = false;
      this.canEdit = false;
      this.canDelete = false;
      this.userRightsService.watchCurrentRoles().pipe(
        takeUntil(this.destroy)
      ).subscribe(userRoles => {
        this.canApprove = this.userRightsService.userHasRight(userRoles, this.eventService.getApprovalRights(event));
        this.canReject = this.userRightsService.userHasRight(userRoles, this.eventService.getRejectionRights(event));
        this.canEdit = this.userRightsService.userHasRight(userRoles, this.eventService.getEditionRights(event));
        this.canDelete = this.eventService.isEventDeletable(userRoles, event);
      });
    } else if (this.isNewItem) {
      this.canApprove = true;
      this.canReject = true;
      this.canEdit = true;
    } else {
      this.canApprove = false;
      this.canReject = false;
      this.canEdit = false;
    }
  }

  /**
   * Show weather details if weather is available
   * @param weather Weather to show
   */
  private showWeatherIfAvailable(weather: Weather): void {
    if (weather) {
      this.weatherCodeDetails = this.weatherService.getWeather(weather);
      this.isPreviewingWeather = true;
    }
  }

  updateSelectedTasks(tasks: EventTaskAdapter[], event: Event): void {
    if (event.task && event.task.taskId && event.task.taskId !== '') {
      const eventTaskAdapter = tasks.find(x => x.task.id === event.task.taskId);
      if (eventTaskAdapter) {
        eventTaskAdapter.updateFromModel(event.task);
        if(eventTaskAdapter.task.plannedQuantity > 0) {
          this.formGroup.controls['quantityDone'].setValue(event.task.quantityDone);
          this.formGroup.controls['quantityUnit'].setValue(eventTaskAdapter.task.quantityUnit);
          this.taskPlannedQuantity = eventTaskAdapter.task.plannedQuantity;
        }
      }
      this.formGroup.controls['eventTaskAdapter'].setValue(eventTaskAdapter);
    }
  }

  onEditClick(): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      this.isEdited = true;
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  beforeSave(status: EventStatus): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      this.statusToSave = status;
      this.backupService.removeBackupItem(BACKUP_TYPES.EVENT);
      this.save();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  public save(): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      if (this.formGroup.valid) {
        this.invalidFormSubmitted = false;
        this.saveItem(this.isNewItem, this.event).then(item => {
          this.isEdited = false;
          this.logger.info('Saved item: ', (item as Event).id);
          this.navigateToItem(item);
          this.editForm.emit(false);
        }).catch(error => {
          if(error.exceptionType === SyncExceptionType.APPROVED_EVENT_MUTATION) {
            let event = this.sharedDataService.processEditApprovedEventSyncException(error);
            this.isEdited = false;
            this.event = event;
            this.navigateToItem(event);
            this.editForm.emit(false);
          }
          else {
            this.logger.error('Failed to save item: ', this.event, error);
          }
        });
      } else {
        this.invalidFormSubmitted = true;
        this.logger.error('Trying to submit an invalid form');
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  protected async saveItem(isNewItem: boolean, event: Event): Promise<Event> {
    await this.prepareEvent(event);
    event.isEventSynced = false;
    if (this.isNewItem) {
      event.status = this.statusToSave;
      event.createdBy = Author.userToAuthor(this.sessionService.getCurrentUser()); /* set by the server but used in event-list before synchronization so it should be set */
      this.attachmentService.addAttachmentToLocallyStoredAttachments(this.getAttachmentsToUpload());
      return this.sharedDataService.createEvent(event).then(async createdEvent => {
        this.logger.info('New Event created successfully. Event is', createdEvent);
        event.id = createdEvent.id;
        this.isNewItem = false;
        await this.attachmentService.uploadAttachmentsInWeb(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId, createdEvent.id, this.getAttachmentsToUpload());
        this.clearAllAttachmentsToUpload();
        // Track action in intercom
        this.intercomService.trackUserAction(IntercomUserActions.AddEvent);
        return createdEvent;
      });
    } else {
      event.updatedOn = new Date();
      event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
      event.modifiedAt = new Date();
      event.status = this.statusToSave;
      this.attachmentService.addAttachmentToLocallyStoredAttachments(this.getAttachmentsToUpload());
      return this.sharedDataService.updateEvent(event).then(async updatedEvent => {
        this.logger.info('Event updated successfully. Event is', updatedEvent);
        await this.attachmentService.uploadAttachmentsInWeb(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId, updatedEvent.id, this.getAttachmentsToUpload());
        this.clearAllAttachmentsToUpload();
        return updatedEvent;
      });
    }
  }

  private prepareEvent(event: Event): void {
    const form = this.formGroup.value;

    event.siteId = this.sharedDataService.currentSiteId;
    event.title = form.title.trim();
    event.description = form.description.trim();
    event.locationObject = form.location;
    event.location = event.locationObject ? event.locationObject.name : '';
    if (form.eventTaskAdapter) {
      form.eventTaskAdapter.updateModel(event.task);
    }
    event.tags = this.tagService.convertTagsToEventTags(form.tags);
    event.contractors = this.contractorService.convertContractorsToEventContractors(form.contractors);
    event.assets = EventAsset.jsonToEventAssets(
      this.formGroup.value.labours.concat(this.formGroup.value.plants).concat(this.formGroup.value.materials));
    event.attachments = form.attachments;
    const startDate: any = new Date(form.startDatetime).getTime();
    const endDate: any = form.endDatetime ? new Date(form.endDatetime).getTime() : null;
    event.startDatetime = startDate;
    event.endDatetime = endDate;

    // if quantityDone is specified update task progress using quantities
    if(form.quantityDone > 0) {
      let taskTotalQuantityDone = this.sharedDataService.getTotalQuantityDoneForTask(form.eventTaskAdapter.task);

      // if an event is edited remove the previous quantityDone from taskTotalQuantityDone and then add the new
      // quantityDone to taskTotalQuantityDone
      if(!this.isNewItem) {
        taskTotalQuantityDone = taskTotalQuantityDone - event.task.quantityDone;
      }
      event.task.quantityDone = form.quantityDone;
      taskTotalQuantityDone += form.quantityDone;
      const taskProgress = Math.floor((taskTotalQuantityDone/this.taskPlannedQuantity) * 100);
      event.task.progress = taskProgress;
      form.eventTaskAdapter.progress = taskProgress;
    }
    event.formLayoutId = this.currentCustomEventForm.id;
    if(this.customFields.value.length > 0) {
      event.customFieldValues = [];
      this.customFields.value.forEach((customFieldValue) => {
        event.customFieldValues.push({uuid: customFieldValue.id, value: customFieldValue.value});
      });
    }

    if (!this.isNewItem) {
      event.id = this.event.id;
    }
  }

  private navigateToItem(event: Event): Promise<boolean> {
    return this.router.navigate(['..', event.id], { relativeTo: this.route });
  }

  getAttachmentsToUpload(): { [attachmentId: string]: string } {
    if (this.eventPhotosComponent) {
      return this.eventPhotosComponent.getAttachmentsToUpload();
    }
    return {};
  }

  clearAllAttachmentsToUpload(): void {
    if (this.eventPhotosComponent) {
      this.eventPhotosComponent.clearAllAttachmentsToUpload();
    }
  }

  submitEvent(): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      // Change event status
      this.event.status = EventStatus.SUBMITTED;
      this.event.modifiedAt = new Date();
      this.sharedDataService.updateEvent(this.event).then(() => {
        // Get new user rights after approval
        this.updateRights(this.sessionService.getCurrentUser(), this.event);
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Open comment modal to input rejection comment.
   */
  showRejectCommentPopup() : void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      const modalRef = this.modalService.open(
        EventApproveRejectConfigurationPopupComponent,
        { centered: true },
      );
      const eventApproveRejectConfigurationComponent: EventApproveRejectConfigurationPopupComponent = modalRef.componentInstance;
      eventApproveRejectConfigurationComponent.isRejectionRequest = true;
      eventApproveRejectConfigurationComponent.setCallbacks(
        result => {
          modalRef.close(result);
          this.rejectEvent(result);
        },
        () => {
          modalRef.dismiss();
        }
      );
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  showRejectAlert(title: string) {
    this.translateService.get([
      'events.detail.reject.question',
      'btn.cancel',
      'events.detail.btn.reject',
    ]).pipe(
      mergeMap(translations => this.alertController.create({
        header: translations['events.detail.reject.question'],
        buttons: [
          {
            text: translations['btn.cancel'],
            role: 'cancel',
            handler: () => {
            },
          },
          {
            text: translations['events.detail.btn.reject'],
            cssClass: 'text-danger font-weight-bold',
            handler: () => {
              // Reject event on confirmation
              this.rejectEvent(title);
            },
          },
        ],
      })),
      mergeMap(alert => alert.present()),
    ).pipe(
      takeUntil(this.destroy)
    ).subscribe();
  }

  /**
   * Open comment modal to input approval comment.
   */
  showApproveCommentPopup() : void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      const modalRef = this.modalService.open(
        EventApproveRejectConfigurationPopupComponent,
        { centered: true },
      );
      const eventApproveRejectConfigurationComponent: EventApproveRejectConfigurationPopupComponent = modalRef.componentInstance;
      eventApproveRejectConfigurationComponent.isApprovalRequest = true;
      eventApproveRejectConfigurationComponent.setCallbacks(
        result => {
          modalRef.close(result);
          this.approveEvent(result);
        },
        () => {
          modalRef.dismiss();
        }
      );
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  showApproveAlert(title: string): void {
    this.translateService.get([
      'events.detail.approve.question',
      'btn.cancel',
      'events.detail.btn.approve',
    ]).pipe(
      mergeMap(translations => this.alertController.create({
        header: translations['events.detail.approve.question'],
        buttons: [
          {
            text: translations['btn.cancel'],
            role: 'cancel',
            handler: () => {
            },
          },
          {
            text: translations['events.detail.btn.approve'],
            cssClass: 'text-success font-weight-bold',
            handler: () => {
              // Approve event on confirmation
              this.approveEvent(title);
            },
          },
        ],
      })),
      mergeMap(alert => alert.present()),
    ).pipe(
      takeUntil(this.destroy)
    ).subscribe();
  }

  private approveEvent(title: string): void {
    this.event.approvalComment = title;
    // Change event updatedBy to current user
    this.event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
    this.event.status = EventStatus.APPROVED;
    this.event.modifiedAt = new Date();
    this.sharedDataService.updateEvent(this.event).then(() => {
      // Change event status
      // Get new user rights after approval
      this.updateRights(this.sessionService.getCurrentUser(), this.event);
    });
  }

  private rejectEvent(title: string): void {
    this.event.approvalComment = title;
    // Change event updatedBy to current user
    this.event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
    this.event.status = EventStatus.REJECTED;
    this.event.modifiedAt = new Date();
    this.sharedDataService.updateEvent(this.event).then(() => {
      // Change event status
      // Get new user rights after rejection
      this.updateRights(this.sessionService.getCurrentUser(), this.event);
    });
  }

  public async delete() {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      this.translateService.get([
        'events.detail.delete.question',
        'btn.cancel',
        'btn.confirm_delete',
      ]).pipe(
        mergeMap(async translations => {
          const alert = await this.alertController.create({
            header: translations['events.detail.delete.question'],
            buttons: [
              {
                text: translations['btn.cancel'],
                role: 'cancel',
                handler: () => {
                },
              },
              {
                text: translations['btn.confirm_delete'],
                cssClass: 'text-danger',
                handler: () => {
                  this.event.modifiedAt = new Date();
                  this.deleteItem(this.event).then(item => {
                    this.logger.info('Deleted item: ', item);
                    this.getBack();
                  }).catch(error => {
                    this.logger.error('Failed to delete item: ', this.event, error);
                  });
                },
              },
            ],
          });
          return await alert.present();
        })
      ).toPromise();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  private deleteItem(event: Event): Promise<Event> {
    return this.sharedDataService.deleteEvent(event)
      .then(deletedEvent => {
        this.toasterService.showSuccessToaster('events.detail.delete.notify.success');
        return deletedEvent;
      }).catch(error => {
        this.toasterService.showErrorToaster('events.detail.delete.notify.error');
        throw error;
      });
  }

  public cancel(): void {
    if (this.isNewItem) {
      this.getBack();
    } else {
      this.isEdited = false;
    }
  }

  public async getBack(): Promise<void> {
    let path = '..';
    this.router.navigate([path], { relativeTo: this.route });
    this.editForm.emit(false);
  }

  public duplicate(message: string): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      // Show a toaster to explain to user that he is now on a copy of the event
      this.toasterService.showSuccessToaster(message);
      this.navigateToDuplicatedEvent(this.event.id);
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
      return null;
    }
  }

  public navigateToDuplicatedEvent(eventIdToDuplicate: string): void {
    this.duplicateEvent(eventIdToDuplicate);
    this.router.navigate(['../new'], { relativeTo: this.route, fragment: 'id:' + eventIdToDuplicate });
  }

  duplicateEvent(eventId: string): void {
    if(eventId) {
      let event = this.event;
      this.event = event.duplicate();
      this.isNewItem = true;
      this.populateFormGroupData(true);
      this.fetchAndPopulateWithEventCustomFormData(this.event, false);
      this.isEdited = true;
    }
  }

  /**
   * Open PDF modal to configure PDF generation
   */
  openPDFReportModalForSingleEvent(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_PDF').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        const modalRef = this.modalService.open(
          PdfExportConfigurationPopupComponent,
          { centered: true },
        );
        const pdfExportConfigurationComponent: PdfExportConfigurationPopupComponent = modalRef.componentInstance;
        pdfExportConfigurationComponent.setCallbacks(
          result => {
            modalRef.close(result);
            this.getPDFReport(result);
          },
          () => {
            modalRef.dismiss();
          }
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Download the PDF file generated by REST API
   */
  getPDFReport(title: string): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSavePdfReport(
      new Date(),
      new Date(),
      this.event.id.split(', '),
      title,
    ).pipe(
      takeUntil(this.destroy),
    ).subscribe(
      () => {
        this.toasterService.showSuccessToaster('events.report.success.download');
        this.spinnerService.deactivate();
      },
      error => {
        this.toasterService.showWarningToaster('events.report.error.download');
        this.spinnerService.deactivate();
      },
    );
  }

  async sendMail(): Promise<void> {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      try {
        this.eventEmailService.sendEventEmail(
          this.event,
          this.formGroup.controls['tags'].value,
          this.formGroup.controls['contractors'].value,
          this.formGroup.controls['labours'].value,
          this.formGroup.controls['plants'].value,
          this.formGroup.controls['materials'].value,
          this.formGroup.controls['eventTaskAdapter'].value,
        );
      } catch (error) {
        this.logger.error("Error sending event email", false);
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  onMapViewStatusChange(value: boolean): void {
    this.isGoogleMapOpened = value;
  }
}
