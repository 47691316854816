import { Component, forwardRef, Input, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  FormGroup,
} from '@angular/forms';
// Translation Service
import { TranslateService } from '@ngx-translate/core';
import { CustomDatepickerI18nService } from '../../../services/i18n/date-internationalization.service';
// Dates Management
import { DateManipulationService } from '../../../services/date-manipulation.service';
// Components
import { UiWebDatetimePickerComponent } from 'app/core/ui/components/web/';
import { UiMobileDatetimePickerComponent } from 'app/core/ui/components/mobile';
// Angular Material Component
import { MatDialog } from '@angular/material/dialog';
// Ionic Components
import { ModalController } from '@ionic/angular';
// Services
import { UiDatetimePickerService } from 'app/core/ui/services';
// Rxjs
import { Subscription } from 'rxjs';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimeInputComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DatetimeInputComponent),
    multi: true
  }],
})
export class DatetimeInputComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() clearable;
  @Input() readonly = false;
  @Input() labelForId: string;
  @Input() placeholder: string;
  @Input() customCssClass: string = '';

  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() defaultDate: string;
  @Input() type: 'startDatetime' | 'endDatetime';
  @Input() canClear: boolean = false;
  @Input() canClearOnlyEndDatetime: boolean = false;
  @Input() hideTime: boolean = false;
  @Input() hideShortcut: boolean = false;

  onChangeCallback: (any) => void;

  value: string | null;
  oldValue: string;

  localizedMonthList: string[];
  localizedFormat: string;
  translations;
  customOptions: any;
  private translateSub: Subscription;

  @ViewChild('ionDatetime') private ionDatetime: any;
  constructor(
    public deviceService: DeviceService,
    private modalController: ModalController,
    private dialog: MatDialog,
    private dateManipulationService: DateManipulationService,
    private customDatepickerI18nService: CustomDatepickerI18nService,
    private translateService: TranslateService,
    private datetimePickerService: UiDatetimePickerService,
    private cdk: ChangeDetectorRef,
  ) {
    this.localizedMonthList = this.customDatepickerI18nService.getMonthList();
    this.translateSub = this.translateService.get(['btn.cancel', 'Ok', 'btn.clear'])
      .subscribe(_translations => this.translations = _translations);
  }

  ngOnInit(): void {
    this.localizedFormat = this.hideTime ? this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime()
    : this.customDatepickerI18nService.getPickerDisplayFormat();

    // Add custom "clear" button when input is set as "clearable" (we have to also manually set Ok and Cancel buttons)+
    this.customOptions = this.clearable ? {
      buttons: [
        {
          text: this.translations['btn.clear'],
          handler: () => this.ionicDateChanged(null)
        },
        {
          text: this.translations['btn.cancel']
        },
        {
          text: this.translations['Ok'],
          handler: (date) => this.ionicDateChanged(date)
        }
      ]
    } : {};
    this.value = new Date().toISOString().slice(0, 16);
  }

  ngOnDestroy(): void {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

  /**
   * Update the value and trigger the change callback
   * @param newValue
   */
  updateValue(newValue: string) {
    this.oldValue = new Date(this.value).toISOString();
    if (this.oldValue !== newValue) {
      this.value = newValue;
      this.oldValue = newValue;
      if (this.onChangeCallback) {
        this.onChangeCallback(newValue);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void { }

  writeValue(obj: any): void {
    if (this.type === 'endDatetime') {
      this.datetimePickerService.endDatetime = obj;
    } else {
      this.datetimePickerService.startDatetime = obj;
    }
    this.value = obj;
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return null;
  }

  ionicDateChanged(date: any) {
    const newDate = this.dateManipulationService.convertIonicToDate(date);
    this.updateValue(this.dateManipulationService.convertDateToIsoString(newDate));
  }

  private setCanClear(): void {
    if (this.canClearOnlyEndDatetime && this.datetimePickerService.type === 'endDatetime') {
      this.datetimePickerService.canClear = this.canClearOnlyEndDatetime;
    }
    else {
      this.datetimePickerService.canClear = this.canClear;
    }
  }

  async openDatetimePicker() {
    if (!this.readonly || this.shouldSetDefaultDate()) {
      this.datetimePickerService.selectedDate = this.value;
      if (this.type === 'startDatetime') {
        this.datetimePickerService.startDatetime = this.value ? new Date(this.value) : null;
      } else if (this.type === 'endDatetime') {
        this.datetimePickerService.endDatetime = this.value ? new Date(this.value) : null;
      }
      this.datetimePickerService.type = this.type;
      this.setCanClear();

      this.datetimePickerService.hideTime = this.hideTime;
      this.datetimePickerService.hideShortcut = this.hideShortcut;
      if (this.deviceService.isMobile) {
        const modal = await this.modalController.create({
          component: UiMobileDatetimePickerComponent,
          componentProps: {
            customEditEndDateFunction: () => this.setCanClear(),
            customEditStartDateFunction: () => this.setCanClear(),
          }
        });
        modal.onDidDismiss().then((val) => {
          const date = this.type === 'startDatetime' ? this.datetimePickerService.startDatetime : this.datetimePickerService.endDatetime;
          this.updateValue(date ? new Date(date).toISOString() : null);
          if (val && val.data && val.data.removeEndDate) {
            this.updateValue(null);
          }
        });
        return await modal.present();
      } else {
        const dialogRef = this.dialog.open(UiWebDatetimePickerComponent, {
          width: '350px',
        });
        dialogRef.afterClosed().subscribe(() => {
          const date = this.type === 'endDatetime' ? this.datetimePickerService.endDatetime : this.datetimePickerService.startDatetime;
          this.updateValue(date ? new Date(date).toISOString() : null);
        });
      }
    }
  }

  shouldSetDefaultDate(): string {
    return !this.value && this.defaultDate;
  }

}
