import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { CustomEventField, CustomEventFieldTypeValue } from '@models/custom-event-field';
import { Event } from '@models/event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-custom-field',
  templateUrl: './event-custom-field.component.html',
  styleUrls: ['./event-custom-field.component.scss']
})
export class EventCustomFieldComponent implements OnInit {

  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() deviceIsMobile: boolean = false;
  @Input() event: Event = null;
  @Input() isEdited: boolean;
  @Input() isMobileEventPreviewComponent: boolean = false;

  get customFields() {
    return this.parentFormGroup.controls["customFields"] as FormArray;
  }

  inputType: string = 'text';
  placeholderText: string = 'custom_event.custom_text_field.placeholder';

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getInputType();
  }

  getInputType(): void {
    if(this.customField.fieldType.value === CustomEventFieldTypeValue.STRING) {
      this.inputType = 'text';
      this.placeholderText = 'custom_event.custom_text_field.placeholder';
    } else if(this.customField.fieldType.value === CustomEventFieldTypeValue.NUMBER) {
      this.inputType = 'number';
      this.placeholderText = 'custom_event.custom_number_field.placeholder';
    }
  }

  getCustomFieldValue(formGroup: FormGroup): string {
    if(formGroup && formGroup.value && formGroup.value.value) {
      return formGroup.value.value;
    } else if(formGroup && formGroup.value && formGroup.value.value === null) {
      let translatedString = '';
      return translatedString;
    }
    else {
      return '';
    }
  }

  getCustomFieldValueFromEvent(customField: CustomEventField): string | number {
    let customFieldValue = null;
    if(this.event) {
      this.event.customFieldValues.forEach((eventCustomField) => {
        if (eventCustomField.uuid === customField.id) {
          customFieldValue = eventCustomField.value;
        }
      });
    }
    if(customFieldValue) {
      return customFieldValue;
    } else {
      let translatedString = '';
      return translatedString;
    }
  }
}
