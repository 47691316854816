import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { SessionService } from '@services/session.service';
import { UserSettingsService } from '@services/user-settings.service';
import { Subject } from 'rxjs';
import { EventEmitter } from 'events';
import { IntercomService } from '@services/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  public signOutSignalObserver: Subject<void> = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private userSettingsService: UserSettingsService,
    private deviceService: DeviceService,
    private router: Router,
    private intercomService: IntercomService,
  ) { }

  disconnectServices(): void {
    this.intercomService.shutdown();
    this.sessionService.removeSession();
    this.userSettingsService.clear();
    if (this.deviceService.isMobile) {
      this.signOutSignalObserver.next();
      this.router.navigate(['welcome']);
    } else {
      this.router.navigate(['login']).then(() => {
      this.signOutSignalObserver.next();
      });
    }
  }

  public addLogoutCallback(callback: () => void): void {
    const sub = this.signOutSignalObserver.subscribe(() => {
      callback();
    });
  }

}
