import { CustomEventFormSection } from "./custom-event-form-layout";
import { ModelElement } from "./model-element";

export enum CustomEventFieldTypeValue {
  STRING = 'String',
  NUMBER = 'Number',
  LEGACY = "Legacy",
}

export class CustomEventFieldType {
  name: string;
  value: CustomEventFieldTypeValue;
}

export class CustomEventLegacyFieldType {
  name: string;
  value: CustomEventLegacyFieldValue;
  active: boolean;
  isVisible: boolean;
}

export enum CustomEventLegacyFieldValue {
  CONTRACTORS = 'CONTRACTOR',
  LOCATION = 'LOCATION',
  PHOTOS = 'PHOTOS',
  TAGS = 'TAGS',
  PEOPLE = 'PEOPLE',
  EQUIPMENT = 'EQUIPMENT',
  MATERIALS = 'MATERIALS',
  NOTES = 'NOTES'
}

export enum CustomEventLegacyFieldTitle {
  CONTRACTOR = "Contractor",
  LOCATION = "Location",
  PHOTOS = "Add photos",
  TAGS = "Tags",
  PEOPLE = "People",
  EQUIPMENT = "Equipment",
  MATERIALS = "Materials",
  NOTES = "Note/Comment",
}

export const allFormSectionTypes: CustomEventFormSection[] = [
  {
    sectionTitle: "Activities information",
    sectionFields: ["CONTRACTOR", "LOCATION", "PHOTOS", "TAGS"],
    isVisible: true,
  },
  {
    sectionTitle: "Resources",
    sectionFields: ["PEOPLE", "EQUIPMENT", "MATERIALS"],
    isVisible: true,
  },
  {
    sectionTitle: "Notes",
    sectionFields: ["NOTES"],
    isVisible: true,
  },
];

export const allLegacyFieldTypes: CustomEventLegacyFieldType[] = [
  { name: 'custom_event_form.legacy_field.contractor', value: CustomEventLegacyFieldValue.CONTRACTORS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.location', value: CustomEventLegacyFieldValue.LOCATION, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.photos', value: CustomEventLegacyFieldValue.PHOTOS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.tags', value: CustomEventLegacyFieldValue.TAGS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.people', value: CustomEventLegacyFieldValue.PEOPLE, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.equipment', value: CustomEventLegacyFieldValue.EQUIPMENT, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.materials', value: CustomEventLegacyFieldValue.MATERIALS, active: true, isVisible: true },
  { name: 'custom_event_form.legacy_field.notes', value: CustomEventLegacyFieldValue.NOTES, active: true, isVisible: true }
]

export class CustomEventField {
  id: string;
  title: string;
  fieldType: CustomEventFieldType;
  isVisible: boolean;
  legacyFieldValue?: CustomEventLegacyFieldType;

  public static CUSTOMEVENTFIELDTYPE: CustomEventFieldType[] = [
    { name: 'custom_event_form.field_type.string', value: CustomEventFieldTypeValue.STRING },
    { name: 'custom_event_form.field_type.number', value: CustomEventFieldTypeValue.NUMBER },
    { name: 'custom_event_form.field_type.legacy', value: CustomEventFieldTypeValue.LEGACY },
  ]

  constructor (
    id: string = ModelElement.generateId(),
    title: string = '',
    fieldType: CustomEventFieldType = CustomEventField.CUSTOMEVENTFIELDTYPE[0],
    isVisible: boolean = true,
    legacyFieldValue = null,
  ) {
    this.id = id;
    this.title = title;
    this.fieldType = fieldType;
    this.isVisible = isVisible;
    this.legacyFieldValue = legacyFieldValue;
  }
}