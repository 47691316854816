import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ResourceType } from '@models/resource-type';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '@services/asset.service';
import { AttachmentService } from '@services/attachment.service';
import { BACKUP_TYPES, BackupService } from '@services/backup/backup.service';
import { ContractorService } from '@services/contractor.service';
import { DeviceService } from '@services/device.service';
import { EventEmailService } from '@services/emails/event-email.service';
import { EventService } from '@services/event.service';
import { FileTransfertService } from '@services/file-transfert.service';
import { CustomDatepickerI18nService, NgbDateParserFormatterI18N } from '@services/i18n/date-internationalization.service';
import { IntercomService } from '@services/intercom.service';
import { SessionService } from '@services/session.service';
import { CoordinatesService } from '@services/shared/coordinates.service';
import { SharedService } from '@services/shared/shared.service';
import { SiteService } from '@services/site.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { WeatherService } from '@services/weather.service';

import { ItemSelectorPopupComponent, SelectionMode } from '../../item-selector-popup/item-selector-popup.component';
import { EventFormComponent } from './event-form.component';
import { DatabaseService } from '@services/shared/database.service';
import { LocationService } from '@services/location.service';
import { EventStatus } from '@models/event';
import { ExportService } from '@services/export.service';
import { SpinnerService } from '@services/spinner.service';
import { ScreenManipulationService } from '@services/screen-manipulation.service';
import { takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { CustomEventFormService } from '@services/custom-event-form.service';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { SharedDataService } from '@services/shared-data.service';
import { ArchiveSitesService } from '@services/archive-sites.service';
import { FaultyEventErrorCodeUtil } from '@models/faulty-event-errors';
import { FaultyEventService } from '@services/faulty-event-service';

@Component({
  selector: 'app-event-form-mobile',
  templateUrl: './event-form-mobile.component.html',
  styleUrls: [
    '../../../../form/abstract-form-component-mobile.sass',
    './event-form-mobile.component.sass'
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter,
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterI18N },
  ],
})
export class EventFormMobileComponent extends EventFormComponent implements OnInit {
  public isCollapsedActivitesInformation = false;
  public isCollapsedResources = false;
  public isCollapsedOtherDetails = false;
  public isCollapsedNotes = false;
  public isCollapsedApprovalComment = false;
  public readonly title = this.isNewItem
    ? 'breadcrumb.new_event' : 'breadcrumb.edit_event' ;
  readonly EventStatus = EventStatus;
  isTabletInLandscape: boolean = false;

  faultyErrorString: Promise<string>;

  constructor(
    protected sharedService: SharedService,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    protected toasterService: ToasterService,
    public deviceService: DeviceService,
    protected alertController: AlertController,
    protected eventService: EventService,
    protected contractorService: ContractorService,
    protected siteService: SiteService,
    protected urlGiver: UrlGiverService,
    protected fileTransfertService: FileTransfertService,
    protected tagService: TagService,
    protected assetService: AssetService,
    protected locationService: LocationService,
    protected coordinatesService: CoordinatesService,
    protected taskService: TaskService,
    protected sessionService: SessionService,
    protected userRightsService: UserRightsService,
    protected attachmentService: AttachmentService,
    protected modalController: ModalController,
    protected weatherService: WeatherService,
    protected eventEmailService: EventEmailService,
    protected intercomService: IntercomService,
    protected formBuilder: FormBuilder,
    protected backupService: BackupService,
    protected databaseService: DatabaseService,
    protected exportService: ExportService,
    protected modalService: NgbModal,
    protected spinnerService: SpinnerService,
    protected screenManipulationService: ScreenManipulationService,
    protected logger: NGXLogger,
    protected customEventFormService: CustomEventFormService,
    protected userAppAccessService: UserAppAccessService,
    protected sharedDataService: SharedDataService,
    protected archiveSitesService: ArchiveSitesService,
    protected faultyEventService: FaultyEventService,
  ) {
    super(
      sharedService,
      route,
      translateService,
      router,
      toasterService,
      deviceService,
      alertController,
      sessionService,
      userRightsService,
      eventService,
      contractorService,
      siteService,
      urlGiver,
      fileTransfertService,
      tagService,
      assetService,
      locationService,
      coordinatesService,
      taskService,
      attachmentService,
      weatherService,
      eventEmailService,
      intercomService,
      formBuilder,
      backupService,
      databaseService,
      exportService,
      modalService,
      spinnerService,
      logger,
      customEventFormService,
      userAppAccessService,
      sharedDataService,
      archiveSitesService,
      faultyEventService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.formGroup.valueChanges.subscribe(() => {
      if (this.formGroup && this.formGroup.dirty) {
        this.backupService.addBackupItem(BACKUP_TYPES.EVENT, this.formGroup.value);
      }
    });
    if (this.backupService.getBackupItem(BACKUP_TYPES.EVENT)) {
      this.confirmLoadBackup();
    }

    if (this.isFaultyEvent) {
      this.setFaultyEventErrorString();
    }

    // checks if device is a tablet in landscape mode and updates the view accordingly
    if(this.deviceService.isTablet) {
      this.screenManipulationService.isTabletInLandscapeMode.pipe(takeUntil(this.unsubscribeIsTabletInLandscapeSubscription)).subscribe((value) => {
        this.isTabletInLandscape = value;
        this.screenManipulationService.updateIsSidebarOpenedInLandscape(false);
      });
    }
  }

  public prepareSubmitEvent(): void {
    this.beforeSave(EventStatus.SUBMITTED);
  }

  private showAlert(
    headerTranslationKey: string,
    acceptTranslationKey: string,
    acceptCallback: () => unknown,
    acceptCssClass: string,
  ): void {
    this.translateService.get([
      headerTranslationKey,
      'btn.cancel',
      acceptTranslationKey,
    ]).subscribe(async translations => {
      const options = {
        header: translations[headerTranslationKey],
        buttons: [{
          text: translations['btn.cancel'],
          role: 'cancel',
          handler: () => {},
        }, {
          text: translations[acceptTranslationKey],
          cssClass: acceptCssClass,
          handler: acceptCallback,
        }],
      };
      const alert = await this.alertController.create(options);
      await alert.present();
    });
  }

  /**
   * Method called on click of OK to refresh event form with newly selected contractor, labours, plants or tags
   */
  updateItems(selected: any[], type: ResourceType): void {
    if (type === 'tasks') {
      this.formGroup.controls['eventTaskAdapter'].setValue(selected[0]);
      this.formGroup.controls['eventTaskAdapter'].markAsDirty();
    }
    else if (type === 'location') {
      this.formGroup.controls[type].setValue(selected[0]);
      this.formGroup.controls[type].markAsDirty();
    }
    else {
      this.formGroup.controls[type].setValue(selected);
      this.formGroup.controls[type].markAsDirty();
    }
  }

  /**
   * Show ionic popup page to select ressources
   * @param data list of items
   * @param selectedData selected items
   * @param type type of items (can be "labours", "plants", "contractors", or "tags")
   */
  async openItemSelectorPopup(data: any[], selectedData: any[], type: ResourceType, selectionMode: SelectionMode) {
    const modal = await this.modalController.create({
      component: ItemSelectorPopupComponent,
      componentProps: {
        items: data,
        selectedItems: selectedData,
        type: type,
        selectionMode: selectionMode,
        canCreate: type !== 'tasks',
        updateItemsFunction: this.updateItems.bind(this)
      },
    });
    return await modal.present();
  }

  protected renewWeatherForNewEvent(): void {
    this.logger.debug('renewWeatherForNewEvent');
  }

  protected onChange(event: CustomEvent) {
    this.formGroup.value.eventTaskAdapter.progress = event.detail.value;
  }

  setFaultyEventErrorString(): void {
    this.faultyErrorString = new Promise<string>((resolve) => {
      this.faultyEventService.getFaultyItemErrorCodeFromDb(this.itemId).then(errorCode => {
        const errorString = FaultyEventErrorCodeUtil.getFaultyEventErrorString(errorCode);
        resolve(errorString);
      }).catch(() => resolve('events.faulty.error.message.DEFAULT'));
    });
  }
}
