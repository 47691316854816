import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, from, Observable, of, AsyncSubject, Subscription } from 'rxjs';
import { filter, first, flatMap, map, tap, catchError, retry, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UrlGiverService } from './url-giver.service';
import { SharedService } from './shared/shared.service';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { ToasterService } from './toaster.service';
import { SubscriptionStatus } from '@models/subscription/subscription-status';
import { SubscriptionResponse } from '@models/subscription/subscription-response';
import { StripePricingPlan, STRIPE_PRICING_PLAN_INTERVALS } from '@models/subscription/stripe-pricing-plan';
import { Price } from '@models/subscription/price';
import { Invoice } from '@models/subscription/invoice';
import { Customer } from '@models/subscription/customer';
import { Coupon } from '@models/subscription/coupon';
import { SubscriptionFeature } from '@models/subscription/subscription-feature';
import { TaxRule } from '@models/subscription/tax-rule';
import { InternationalizationService } from '@services/i18n/internationalization.service';
import {NetworkStatus} from "@models/synchronization/network-status";
import { NGXLogger } from 'ngx-logger';
import { UserAppAccessDAO } from './user-app-access/user-app-access-dao.service';
import { AppTypeService } from './app-type.service';
import { SharedDataService } from './shared-data.service';
import { DeviceService } from './device.service';

declare var Stripe: stripe.StripeStatic;

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private static readonly STRIPE_PUBLISHABLE_KEY: string = environment.stripePublishableKey;

  /**
   * List of zero-decimal currencies supported by Stripe
   * See https://stripe.com/docs/currencies#zero-decimal
   */
  private static readonly ZERO_DECIMAL_CURRENCIES =
    ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf'];

  private static readonly MAX_RETRY_ATTEMPTS = 3;

  private _stripe: stripe.Stripe;
  currentSpaceId: string;
  private get stripe(): stripe.Stripe {
    if (!this._stripe) {
      this._stripe = Stripe(StripeService.STRIPE_PUBLISHABLE_KEY, {});
    }
    return this._stripe;
  }
  private apiStripeUrl$: Observable<string>;
  private apiTaxUrl: string;

  private triggerSubscriptionStatusReload$: BehaviorSubject<void> = new BehaviorSubject(null);
  private selectedPricingPlan$: BehaviorSubject<StripePricingPlan> = new BehaviorSubject(null);
  private selectedCountry$: BehaviorSubject<string> = new BehaviorSubject(null);
  private selectedCoupon$: BehaviorSubject<Coupon> = new BehaviorSubject(null);
  private taxPercentage$: BehaviorSubject<number> = new BehaviorSubject(null);

  private cachedTaxRules: Map<string, AsyncSubject<TaxRule>> = new Map();
  private cachedCoupons: Map<string, AsyncSubject<Coupon>> = new Map();

  private get lastKnownSubscriptionStatus(): SubscriptionStatus {
    let spaceId: string = this.currentSpaceId;
    const lastKnownSubscriptionStatusJson = localStorage.getItem('lastKnownSubscriptionStatus');
    if (lastKnownSubscriptionStatusJson) {
      const lastKnownSubscriptionStatus = JSON.parse(lastKnownSubscriptionStatusJson);
      if (lastKnownSubscriptionStatus && lastKnownSubscriptionStatus[spaceId]) {
        return lastKnownSubscriptionStatus[spaceId];
      }
    }
    return null;
  }
  private set lastKnownSubscriptionStatus(status: SubscriptionStatus) {
    if (this.currentSpaceId && status) {
      const lastKnownSubscriptionStatusJson = localStorage.getItem('lastKnownSubscriptionStatus');
      let lastKnownSubscriptionStatus = {};
      if (lastKnownSubscriptionStatusJson) {
        lastKnownSubscriptionStatus = JSON.parse(lastKnownSubscriptionStatusJson) || {};
      }
      lastKnownSubscriptionStatus[this.currentSpaceId] = status;
      localStorage.setItem('lastKnownSubscriptionStatus', JSON.stringify(lastKnownSubscriptionStatus));
    }
  }

  /**
   * Information about current space
   */
  public readonly forCurrentSpace: {
    status: Observable<SubscriptionStatus>;
    features: Observable<SubscriptionFeature>;
    invoices: Observable<Invoice[]>;
    plans: Observable<StripePricingPlan[]>;
    siteDiaryPayingUserCount: Observable<number>;
    siteTaskPayingUserCount: Observable<number>;
    totalPrice: Observable<Price>;
  };

  private readonly forCurrentSpaceSubjects: {
    status: BehaviorSubject<SubscriptionStatus>;
    invoices: BehaviorSubject<Invoice[]>;
    plans: BehaviorSubject<StripePricingPlan[]>;
    userCount: BehaviorSubject<number>;
  };

  private subjectSubscription: {
    invoices: Subscription;
    plans: Subscription;
    userCount: Subscription;
  };

  public readonly selectedCountryTaxRule: Observable<TaxRule>;

  constructor(
    private urlGiver: UrlGiverService,
    private https: HttpClient,
    private sharedService: SharedService,
    private userService: UserService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private logger: NGXLogger,
    private userAppAccessDAO: UserAppAccessDAO,
    private appTypeService: AppTypeService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService
  ) {
    let relevantSharedService: SharedDataService | SharedService;
    if ((this.deviceService.isMobile) || (this.deviceService.isMobile && this.deviceService.isMobileWeb)) {
      relevantSharedService = this.sharedService;
    } else {
      relevantSharedService = this.sharedDataService;
    }
    relevantSharedService.watchSpaceId.subscribe(spaceId => {
      this.currentSpaceId = spaceId;
    });
    const notNull = (value) => value !== null;
    this.apiStripeUrl$ = relevantSharedService.watchSpaceId.pipe(
      filter(notNull),
      map(spaceId => `${this.urlGiver.giveAPIUrl()}/tenant/${spaceId}/stripe`),
    );
    this.apiTaxUrl = `${this.urlGiver.giveAPIUrl()}/tax`;
    this.selectedCountryTaxRule = this.selectedCountry$.pipe(
      flatMap(country => this.getRemoteOrCachedTaxRule(country)),
    );
    this.forCurrentSpaceSubjects = {
      status: new BehaviorSubject(null),
      invoices: null,
      plans: null,
      userCount: null,
    };
    this.subjectSubscription = {
      invoices: null,
      plans: null,
      userCount: null,
    };
    this.forCurrentSpace = {
      status: this.forCurrentSpaceSubjects.status.pipe(filter(notNull)),
      features: this.forCurrentSpaceSubjects.status.pipe(
        filter(notNull),
        map(status => status.subscriptionLevel.features),
      ),
      invoices: this.makeColdMulticast(
        this.triggerSubscriptionStatusReload$.pipe( flatMap(() => this.getRemoteInvoices())),
        'invoices',
      ),
      plans: this.makeColdMulticast(
        this.triggerSubscriptionStatusReload$.pipe(
          flatMap(() => this.getRemotePlans()),
          map(plans => plans.sort((planA, planB) => this.comparePricingPlans(planA, planB))),
        ),
        'plans',
      ),
      siteDiaryPayingUserCount: this.makeColdMulticast(
        relevantSharedService.watchSpaceId.pipe(
          filter(notNull),
          flatMap(spaceId => this.userService.getBackendSpaceUsers(spaceId)),
          map(spaceUsers => {
            let siteDiaryUserCount = 0;
            spaceUsers.forEach((spaceUser) => {
              if(spaceUser.siteDiaryAccess && spaceUser.active) {
                siteDiaryUserCount += 1;
              }
            });
            return siteDiaryUserCount;
          }),
        ),
        'siteDiaryUserCount',
      ),
      siteTaskPayingUserCount: this.makeColdMulticast(
        relevantSharedService.watchSpaceId.pipe(
          filter(notNull),
          flatMap(spaceId => this.userService.getBackendSpaceUsers(spaceId)),
          map(spaceUsers => {
            let siteTaskUserCount = 0;
            spaceUsers.forEach((spaceUser) => {
              if(spaceUser.siteTaskAccess && spaceUser.active) {
                siteTaskUserCount += 1;
              }
            });
            return siteTaskUserCount;
          }),
        ),
        'siteTaskUserCount',
      ),
      totalPrice: null,
    };
    this.forCurrentSpace.totalPrice = combineLatest(
      this.selectedPricingPlan,
      this.forCurrentSpace.siteDiaryPayingUserCount.pipe(filter(notNull)),
      this.forCurrentSpace.siteTaskPayingUserCount.pipe(filter(notNull)),
      this.taxPercentage,
      this.selectedCoupon
    ).pipe(
      map(([pricingPlan, siteDiaryUserCount, siteTaskUserCount, taxPercentage, coupon]) =>
        this.computeTotalPrice(
          {currency: pricingPlan.currency, amount: pricingPlan.siteDiaryPrice}, 
          {currency: pricingPlan.currency, amount: pricingPlan.siteTaskPrice}, 
          siteDiaryUserCount, siteTaskUserCount, coupon, taxPercentage)),
    );

    /**
     * Due to an issue where the status call was not being made for one of the users,
     * we have added this call as a fallback. Assuming that the network status check was causing this issue, 
     * the user should now be able to get the Stripe status successfully. If not, this confirms that the issue is
     * not from the application, rather is being affected by an external factor.
     * 
     * NOTE: THIS IS A TEMPORARY FIX AND SHOULD BE REMOVED SOON.
     */
    this.apiStripeUrl$.subscribe((apiBaseUrl) => {
      this.logger.info('Attempting to fetch status: ', apiBaseUrl);
      this.https.get<SubscriptionStatus>(apiBaseUrl + '/status', { responseType: 'json' }).pipe(
        retry(StripeService.MAX_RETRY_ATTEMPTS)
      ).toPromise().then((response) => {
        this.logger.info('Latest status fetched successfully: ', response);
        this.lastKnownSubscriptionStatus = response;
        this.updateUserAppAccess();
        this.forCurrentSpaceSubjects.status.next(response);
      }).catch((error) => {
        this.logger.error('[STRIPE_STATUS_ERROR] Error fetching Stripe Status: ', {...error, lastKnownSubscriptionStatus: this.lastKnownSubscriptionStatus});
        this.updateUserAppAccess();
        this.forCurrentSpaceSubjects.status.next(this.lastKnownSubscriptionStatus);
      })
    })

    this.triggerSubscriptionStatusReload$.pipe(
      flatMap(() => this.getRemoteSubscriptionStatus()),
      tap(status => this.lastKnownSubscriptionStatus = status),
    ).subscribe(subscriptionStatus => {
      this.updateUserAppAccess();
      return this.forCurrentSpaceSubjects.status.next(subscriptionStatus)
    });
  }

  /**
   * Convert observable to cold multicast observable
   * This means that :
   *   - one 'subscribe' call -> the returned Observable will execute the input source Observable once
   *   - multiple 'subscribe' calls -> the returned Observable will execute the input source Observable only once,
   *   - zero 'subscribe' calls -> the returned Observable will not execute the input source Observable at all.
   * In this service, this is used to prevent multiple identical requests to be sent to the server.
   * @param source Cold observable
   * @param subjectName Name of the subject. Must be the same as in {@link forCurrentSpaceSubjects}
   * object as well as {@link subjectSubscription}
   * @return Cold multicast observable representing the input observable but preventing multiple calls
   */
  private makeColdMulticast<T>(source: Observable<T>, subjectName: 'invoices' | 'plans' | 'siteDiaryUserCount' | 'siteTaskUserCount'): Observable<T> {
    const notNull = (value) => value !== null;
    return Observable.create(observer => {
      /*
       * When first calling 'subscribe' on the returned observable, create subject and link it to source
       */
      if (!this.forCurrentSpaceSubjects[subjectName]) {
        this.forCurrentSpaceSubjects[subjectName] = new BehaviorSubject(null);
        this.subjectSubscription[subjectName] = source.subscribe(
          value => (<BehaviorSubject<any>>this.forCurrentSpaceSubjects[subjectName]).next(value),
          error =>  (<BehaviorSubject<any>>this.forCurrentSpaceSubjects[subjectName]).error(error),
        );
      }

      /*
       * Subscribe to subject and link it to returned observable
       */
      const subject = this.forCurrentSpaceSubjects[subjectName] as BehaviorSubject<unknown>;
      const sub = subject.pipe(filter(notNull)).subscribe(value => observer.next(value));

      /*
       * Unsubscribe from subject when unsubscribing from returned Observable.
       * If no observers are left subscribed to this subject, delete it and unsubscribe from it.
       */
      return () => {
        sub.unsubscribe();
        if (this.forCurrentSpaceSubjects[subjectName].observers.length === 0) {
          this.subjectSubscription[subjectName].unsubscribe();
          this.forCurrentSpaceSubjects[subjectName] = null;
        }
      };
    });
  }

  /**
   * Convert amount of money from Stripe format to readable format
   * @param stripeAmount amount in Stripe format (e.g. 999)
   * @param currency Currency the amount is expressed in (e.g. 'eur')
   * @return Amount in readable format (e.g. 9.99)
   */
  private stripeAmountToDisplayedAmount(stripeAmount: number, currency: string): number {
    return currency in StripeService.ZERO_DECIMAL_CURRENCIES
      ? stripeAmount
      : stripeAmount / 100;
  }

  private formatVatNumber(vatNumber: string): string {
    return vatNumber ? vatNumber.replace(/\s/g, '') : '';
  }

  /**
   * Compare pricing plans so they can be sorted
   * @return List of plans sorted by currency, then interval (assuming there is no 18-months plan or such plans)
   */
  private comparePricingPlans(planA: StripePricingPlan, planB: StripePricingPlan): number {
    const currencyCompare = planA.currency.localeCompare(planB.currency);
    if (currencyCompare !== 0) {
      return currencyCompare;
    }
    const intervalCompare = STRIPE_PRICING_PLAN_INTERVALS.indexOf(planB.interval) - STRIPE_PRICING_PLAN_INTERVALS.indexOf(planA.interval);
    if (intervalCompare !== 0) {
      return intervalCompare;
    }
    const intervalCountCompare = planB.intervalCount - planA.intervalCount;
    return intervalCountCompare;
  }

  /**
   * Return the longest plan with the given currency
   * @param plans List of available plans
   * @param currency Currency to choose from
   */
  private getPlanWithCurrency(plans: StripePricingPlan[], currency: string): StripePricingPlan {
    const plansWithCurrency = plans.filter(plan => plan.currency === currency);
    return plansWithCurrency[0]; // 'plans' should already be sorted
  }

  /**
   * Guess preferred currency of user according their locale
   * @return Uppercase three-letter string representing the preferred currency
   */
  private guessPreferredCurrency(): string {
    const locale = this.internationalizationService.currentLocale;
    if (locale.startsWith('fr')) {
      return 'EUR';
    }
    switch (locale) {
      case 'en-au':
        return 'AUD';
      case 'en-ca':
        return 'CAD';
      case 'en':
      case 'en-gb':
        return 'GBP';
      case 'en-us':
        return 'USD';
      default:
        return 'EUR';
    }
  }

  /**
   * Guess preferred pricing plan
   * @param All available plans
   * @return Plan with preferred currency with the longest interval
   * @link #guessPreferredCurrency
   */
  public guessPreferredPlan(plans: StripePricingPlan[]): StripePricingPlan {
    let preferredPlan = this.getPlanWithCurrency(plans, this.guessPreferredCurrency());
    if (!preferredPlan) {
      preferredPlan = plans[0];
    }
    return preferredPlan;
  }

  /**
   * Fetch subscription status from server
   * @return Status of current space's subscription
   */
  private getRemoteSubscriptionStatus(): Observable<SubscriptionStatus> {
    return NetworkStatus.waitForOnlineStatus().pipe(
      flatMap(() => {
        this.logger.info('[NETWORK_STATUS_ONLINE] Wait for Online status functional: StripeService');
        return this.apiStripeUrl$
      }),
      map(baseUrl => {
        return `${baseUrl}/status`
      }),
      flatMap(statusUrl => {
        this.logger.info('Fetching stripe status: ', statusUrl);
        return this.https.get<SubscriptionStatus>(statusUrl, { responseType: 'json' })
      }),
      retry(StripeService.MAX_RETRY_ATTEMPTS),
      catchError(error => {
        this.logger.error('Error fetching Stripe Status: ', {...error, lastKnownSubscriptionStatus: this.lastKnownSubscriptionStatus});
        return of(this.lastKnownSubscriptionStatus);
      }),
      tap(status => {
        if (!status) {
          this.toasterService.showErrorToaster('payment.status.requesterror');
        }
      }),
    );
  }

  /**
   * Fetch pricing plans available to space from server
   * @return List of plans available for this space
   */
  private getRemotePlans(): Observable<StripePricingPlan[]> {
    return this.apiStripeUrl$.pipe(
      map(baseUrl => `${baseUrl}/plans`),
      flatMap(plansUrl =>
        this.https.get<StripePricingPlan[]>(plansUrl, { responseType: 'json', })
        .pipe(
          retry(StripeService.MAX_RETRY_ATTEMPTS),
          catchError(error => {
            this.logger.error('Error while fetching pricing plans, treating as no pricing plan', error);
            return of([] as StripePricingPlan[]);
          }),
        )
      ),
      map(plans => plans.map(plan => ({
        ...plan,
        currency: plan.currency.toUpperCase(),
        amount: this.stripeAmountToDisplayedAmount(plan.siteDiaryPrice, plan.currency),
        siteDiaryPrice: this.stripeAmountToDisplayedAmount(plan.siteDiaryPrice, plan.currency),
        siteTaskPrice: this.stripeAmountToDisplayedAmount(plan.siteTaskPrice, plan.currency),
      }))),
    );
  }

  /**
   * Fetch invoices from server
   * @return List of past invoices and, if available, next upcoming invoice
   */
  private getRemoteInvoices(): Observable<Invoice[]> {
    return this.apiStripeUrl$.pipe(
      map(baseUrl => `${baseUrl}/invoices`),
      flatMap(invoicesUrl =>
        this.https.get<Invoice[]>(invoicesUrl, { responseType: 'json' })
        .pipe(
          catchError(error => {
            this.logger.error('Error while fetching invoices, treating as no invoice', error);
            return of([] as Invoice[]);
          }),
        )
      ),
      map(invoices => invoices.map(invoice => ({
        ...invoice,
        priceAmount: this.stripeAmountToDisplayedAmount(invoice.priceAmount, invoice.currencySymbol),
        currencySymbol: invoice.currencySymbol.toUpperCase(),
      }))),
    );
  }

  /**
   * Get tax rule for given country
   * @param countryCode alpha2 country code of the country
   * @return Tax rules to apply for given country
   */
  private getRemoteOrCachedTaxRule(countryCode: string): Observable<TaxRule> {
    if (!this.cachedTaxRules.has(countryCode)) {
      const subject = new AsyncSubject<TaxRule>();
      this.cachedTaxRules.set(countryCode, subject);
      this.getRemoteTaxRule(countryCode).subscribe(taxRule => {
        subject.next(taxRule);
        subject.complete();
      });
    }
    return this.cachedTaxRules.get(countryCode).asObservable();
  }

  /**
   * Get tax rule for given country from remote server
   * @param countryCode alpha2 country code of the country
   * @return Tax rules to apply for given country
   */
  private getRemoteTaxRule(countryCode: string): Observable<TaxRule> {
    if (!countryCode) {
      return of({ type: 'NO_TAX' } as TaxRule);
    }
    const requestUrl = `${this.apiTaxUrl}/rule/${countryCode}`;
    return this.https.get<TaxRule>(requestUrl, { responseType: 'json'})
    .pipe(
      first(),
      catchError(error => {
        this.logger.error(`Error while fetching tax rule for country ${countryCode}, treating as no tax`, error);
        return of(null);
      }),
    );
  }

  /**
   * @return Stripe Elements helper object
   */
  public get elements(): stripe.elements.Elements {
    return this.stripe.elements();
  }

  /**
   * Get coupon if it exists
   * @param couponCode Coupon code to retrieve
   * @return Coupon information
   */
  public getCoupon(couponCode: string): Observable<Coupon> {
    if (!couponCode) {
      return of(null);
    }
    if (!this.cachedCoupons.has(couponCode)) {
      const subject = new AsyncSubject<Coupon>();
      this.cachedCoupons.set(couponCode, subject);
      this.getRemoteCoupon(couponCode).subscribe(coupon => {
        subject.next(coupon);
        subject.complete();
      });
    }
    return this.cachedCoupons.get(couponCode).asObservable();
  }

  /**
   * Fetch coupon from server if it exists
   * @param couponCode Coupon code to fetch
   * @return Coupon information
   */
  private getRemoteCoupon(couponCode: string): Observable<Coupon> {
    return this.apiStripeUrl$.pipe(
      first(),
      map(baseUrl => `${baseUrl}/coupon`),
      flatMap(requestUrl =>
        this.https.get<Coupon>(requestUrl, { responseType: 'json', params: { coupon: couponCode }})
        .pipe(
          catchError(error => {
            this.logger.error(`Error while fetching coupon code ${couponCode}, treating as invalid coupon`, error);
            return of({ valid: false } as Coupon);
          }),
        )
      ),
      first(),
      map(coupon => {
        if (coupon && coupon.valid && coupon.type === 'amount') {
          return {
            ...coupon,
            amountOff: this.stripeAmountToDisplayedAmount(coupon.amountOff, coupon.currency),
            currency: coupon.currency.toUpperCase(),
          };
        }
        else {
          return coupon;
        }
      }),
    );
  }

  /**
   * Check validity of VAT number against EU web service
   * @param vatNumber VAT number to check
   * @return true if VAT number is considered valid by EU web service
   *         false otherwise
   */
  public isVatNumberValid(vatNumber: string): Observable<boolean> {
    vatNumber = this.formatVatNumber(vatNumber);
    if (vatNumber) {
      const requestUrl = `${this.apiTaxUrl}/check/vat`;
      return this.https.get<boolean>(requestUrl, { responseType: 'json', params: {vatNumber: vatNumber}})
      .pipe(
        catchError(error => {
          this.logger.error(`Error while validating VAT number ${vatNumber}, treating as null`, error);
          return of(null);
        }),
      );
    }
    return of(null);
  }

  /**
   * Start subscription for current space
   * @param cardElement Stripe Element containing payment information
   * @param pricingPlan Selected Stripe pricing plan (including currency)
   * @param customer Customer billing information
   * @param coupon Optional coupon code
   * @return () when payment has been submitted
   */
  public submitCardPayment (
    cardElement: stripe.elements.Element,
    pricingPlan: string,
    customer: {
      email: string,
      fullName: string,
      address: string,
      city: string,
      zipCode: string,
      countryCode: string,
      vatNumber?: string,
      coupon?: string,
    },
  ): Observable<void> {
    if (customer.vatNumber) {
      customer.vatNumber = this.formatVatNumber(customer.vatNumber);
    }
    return combineLatest(
      from(this.stripe.createPaymentMethod('card', cardElement)),
      this.apiStripeUrl$,
    ).pipe(
      first(),
      flatMap(([paymentMethodResponse, baseUrl]) => {
        if (paymentMethodResponse.error) {
          throw paymentMethodResponse.error;
        }
        return this.https.put<SubscriptionResponse>(`${baseUrl}/subscription/${pricingPlan}`, {
          ...customer,
          paymentMethod: paymentMethodResponse.paymentMethod.id,
        }, {
          responseType: 'json',
        });
      }),
      flatMap(subscriptionResponse => {
        if (subscriptionResponse.status.status === 'incomplete') {
          return from(this.stripe.handleCardPayment(
            subscriptionResponse.paymentIntentClientSecret,
            cardElement,
            ));
        }
        return of({});
      }),
      map((response: any) => {
        if (response.error) {
          throw response.error;
        }
      }),
      tap(() => this.triggerSubscriptionStatusReload$.next(null)),
    );
  }

  updateCardDetails(customer: Customer, cardElement: stripe.elements.Element, cardHolderName: string): Observable<void> {
    if (customer.vatNumber) {
      customer.vatNumber = this.formatVatNumber(customer.vatNumber);
    }
    return combineLatest(
      from(this.stripe.createPaymentMethod('card', cardElement, {
        billing_details: {
          name: cardHolderName
        }
      })),
      this.apiStripeUrl$,
    ).pipe(
      first(),
      flatMap(([paymentMethodResponse, baseUrl]) => {
        if (paymentMethodResponse.error) {
          throw paymentMethodResponse.error;
        }
        return this.https.put(`${baseUrl}/update-payment-method/${paymentMethodResponse.paymentMethod.id}`, {
          responseType: 'json',
        });
      }),
      flatMap(subscriptionResponse => {
        console.log(subscriptionResponse);
        return of({});
      }),
      map((response: any) => {
        if (response.error) {
          throw response.error;
        }
      }),
    );
  }

  /**
   * Stop subscription for current space
   * @param planId ID of the plan the space is subscribed to
   * @return () when unsubscription is successful
   */
  public unsubscribe(planId: string): Observable<void> {
    return this.apiStripeUrl$.pipe(
      first(),
      map(baseUrl => `${baseUrl}/subscription/${planId}`),
      flatMap(requestUrl => this.https.delete<void>(requestUrl, { responseType: 'json', })),
      tap(() => this.triggerSubscriptionStatusReload$.next(null)),
    );
  }

  /**
   * Reenable subscription for current space
   * @param planId ID of the plan the space is subscribed to
   * @return () when subscription is successful
   */
  public resubscribe(planId: string): Observable<void> {
    return this.apiStripeUrl$.pipe(
      first(),
      map(baseUrl => `${baseUrl}/subscription/${planId}`),
      flatMap(requestUrl => this.https.post<void>(requestUrl, { responseType: 'json', })),
      tap(() => this.triggerSubscriptionStatusReload$.next(null)),
    );
  }

  /**
   * Fetch customer information for current space
   * @return Customer details for current space if available
   */
  public getCustomerInformation(): Observable<Customer> {
    return this.apiStripeUrl$.pipe(
      first(),
      map(baseUrl => `${baseUrl}/customerinfo`),
      flatMap(requestUrl => this.https.get<Customer>(requestUrl, { responseType: 'json', })),
      catchError(error => {
        this.logger.error(`Error while fetching customer details, treating as null`, error);
        return of(null);
      }),
    );
  }

  public updateCustomerInformation(customer: Customer): Observable<Customer> {
    return this.apiStripeUrl$.pipe(
      first(),
      map(baseUrl => `${baseUrl}/customer-info`),
      flatMap(requestUrl => this.https.put<Customer>(requestUrl, customer)),
      catchError(error => {
        this.logger.error(`Error while updating customer details, treating as null: `, error);
        return of(null);
      })
    )
  }

  public currentSpaceHasFeature(feature: SubscriptionFeature): Observable<boolean> {
    return this.forCurrentSpace.features.pipe(
      map(features => features.includes(feature)),
    );
  }

  public currentSpaceHasSiteTaskFeature(): Observable<boolean> {
    return this.forCurrentSpace.status.pipe(
      map(status => (status.status === 'trialing' || (status.siteTaskPlanId !== null && status.siteTaskPlanId !== ''))),
    );
  }

  public currentSpaceHasPaidSubscription(): boolean {
    if(!this.appTypeService.isInAdministrationPage()) {
      const selectedApp = this.appTypeService.getCurrentAppType();
      if (selectedApp && selectedApp === 'diary') {
        return (this.lastKnownSubscriptionStatus.status === 'active' && (this.lastKnownSubscriptionStatus.planId !== null || this.lastKnownSubscriptionStatus.planId !== ''));
      } 
      else if (selectedApp && selectedApp === 'tasks') {
        return (this.lastKnownSubscriptionStatus.siteTaskPlanId !== null || this.lastKnownSubscriptionStatus.siteTaskPlanId !== '');
      }
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   * Mark given plan as selected.
   * @see selectedPricingPlan
   * @param plan Plan to select
   */
  public selectPricingPlan(plan: StripePricingPlan): void {
    this.selectedPricingPlan$.next(plan);
  }
  /**
   * Selected plan
   * @see selectPricingPlan
   */
  public get selectedPricingPlan(): Observable<StripePricingPlan> {
    return this.selectedPricingPlan$.asObservable();
  }

  /**
   * Mark given gountry as selected billing country
   * @see selectedCountry
   * @param countryCode alpha2 country code of the country to be selected
   */
  public selectCountry(countryCode: string): void {
    this.selectedCountry$.next(countryCode);
  }
  /**
   * Selected billing country
   * @see selectCountry
   */
  public get selectedCountry(): Observable<string> {
    return this.selectedCountry$.asObservable();
  }

  /**
   * Mark given coupon as selected
   * @see selectedCoupon
   * @param coupon Coupon to select
   */
  public selectCoupon(coupon: Coupon): void {
    this.selectedCoupon$.next(coupon);
  }
  /**
   * Selected coupon
   * @see selectCoupon
   */
  public get selectedCoupon(): Observable<Coupon> {
    return this.selectedCoupon$.asObservable();
  }

  /**
   * Set tax percentage to apply
   * @see taxPercentage
   * @param percentage New tax percentage to apply
   */
  public setTaxPercentage(percentage: number): void {
    this.taxPercentage$.next(percentage);
  }
  /**
   * Applied tax percentage
   * @see setTaxPercentage
   */
  public get taxPercentage(): Observable<number> {
    return this.taxPercentage$.asObservable();
  }

  /**
   * Compute total price optionally modified by a coupon or a tax
   * @param unitPrice Base price for a single user
   * @param userCount Number of non-free users for the subscription
   * @param coupon Optional coupon to apply
   * @param taxPercentage Optional tax to apply
   * @return Total price for a subscription
   */
  public computeTotalPrice(siteDiaryPrice: Price, siteTaskPrice: Price, siteDiaryUserCount: number, siteTaskUserCount: number, coupon: Coupon, taxPercentage: number): Price {
    if (!siteDiaryPrice && !siteTaskPrice) {
      return null;
    }
    return {
      amount: this.applyTax(this.applyCoupon(this.computeTotalSiteProductivityPrice(siteDiaryPrice, siteTaskPrice, siteDiaryUserCount, siteTaskUserCount), coupon), taxPercentage),
      currency: siteDiaryPrice.currency,
    };
  }

  private computeTotalSiteProductivityPrice(siteDiaryPrice: Price, siteTaskPrice: Price, siteDiaryUserCount: number, siteTaskUserCount: number): number {
    return ((siteDiaryPrice.amount * siteDiaryUserCount) + (siteTaskPrice.amount * siteTaskUserCount));
  }

  private applyCoupon(baseAmount: number, coupon: Coupon): number {
    if (coupon && coupon.valid) {
      switch (coupon.type) {
        case 'amount':
          return baseAmount - coupon.amountOff;
        case 'percentage':
          return baseAmount * (1 - coupon.percentageOff / 100);
      }
    }
    return baseAmount;
  }

  private applyTax(baseAmount: number, taxPercentage: number): number {
    return baseAmount * (1 + (taxPercentage / 100));
  }

  /**
   * Fetch and update the app access rights of the user
   */
  private updateUserAppAccess(): void {
    if(this.currentSpaceId && ((this.deviceService.isMobile) || (this.deviceService.isMobile && this.deviceService.isMobileWeb))) {
      NetworkStatus.waitForOnlineStatus().pipe(take(1)).subscribe(() => {
        let appAccessUrl = this.urlGiver.giveAppAccessAPIUrl(this.currentSpaceId);
        this.https.get<{siteDiaryAccess: boolean, siteTaskAccess: boolean}>(appAccessUrl).subscribe((appAccess) => {
          if(appAccess) {
            this.userAppAccessDAO.addAppAccess(this.currentSpaceId, appAccess.siteDiaryAccess, appAccess.siteTaskAccess);
          }
        }),
        (error) => {
          this.logger.error("Error while fetching the app access of the user", error);
        }
      });
    }
  }

  getLastKnownSubscriptionStatus(): SubscriptionStatus {
    return this.lastKnownSubscriptionStatus;
  }

  updatedCardDetails(): void {
    return this.triggerSubscriptionStatusReload$.next(null);
  }
}
