
<ng-container *ngIf="deviceService.isMobile;then mobileTemplate else webappTemplate;"></ng-container>

<ng-template #webappTemplate>
  <div *ngIf="appType==='diary'">
    <div
      class="add-image-btn"
      *ngIf="this.stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async; else fakeImageInputButton"
      [hidden]="readOnly">
      <input
        class="input-file"
        id="my-file"
        type="file"
        [multiple]="stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async"
        accept="image/*"
        (change)="uploadImages($event?.target?.files)">

      <label for="my-file" tabindex="0">
        <div class="icon">
          <ion-icon name="custom-camera"></ion-icon>
        </div>
          &nbsp;&nbsp;+&nbsp;&nbsp;<span>{{ 'events.edit.field.photo' | translate }}</span>
      </label>
    </div>
    <ng-template #fakeImageInputButton>
      <div class="add-image-btn" [hidden]="readOnly">
        <div class="input-file-trigger">
          <label for="NOTHING" tabindex="0" (click)="annoyUser()">
            <ion-icon name="custom-camera"></ion-icon>
            <br />
            {{ 'events.edit.field.photo' | translate }}
          </label>
        </div>
      </div>
    </ng-template>
    <div  class="ngx-viewer">
      <div class="image-card" *ngFor="let attachment of attachments">
        <img class="cursor-on" [src]="getPictureUrl(attachment) | secureImageSrc | async">
        <ion-fab-button *ngIf="!readOnly" (click)="removeAttachment(attachment)" color="danger">
          <ion-icon name="custom-delete"></ion-icon>
        </ion-fab-button>
        <ion-label *ngIf="!readOnly && isNewEvent" class="edit-icon" (click)="editImage(attachment)">
          <ion-icon mode="md" name="create-outline"></ion-icon>
        </ion-label>
      </div>
    </div>
  </div>

  <div *ngIf="appType==='task'">
    <div  class="ngx-viewer">
      <div class="image-card" *ngFor="let attachment of attachments">
        <img class="cursor-on" [src]="getPictureUrl(attachment) | secureImageSrc | async">
        <ion-fab-button *ngIf="!readOnly" (click)="removeAttachment(attachment)" color="danger">
          <ion-icon name="custom-delete"></ion-icon>
        </ion-fab-button>
        <ion-label *ngIf="!readOnly && isNewEvent" class="edit-icon" (click)="editImage(attachment)">
          <ion-icon mode="md" name="create-outline"></ion-icon>
        </ion-label>
      </div>
    </div>

    <div
      class="add-image-btn"
      *ngIf="attachments?.length === 0 || (this.stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async); else fakeImageInputButton"
      [hidden]="readOnly">
      <input
        class="input-file"
        id="my-file"
        type="file"
        [multiple]="stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async"
        accept="image/*"
        (change)="uploadImages($event?.target?.files)">

      <label for="my-file" class="btn btn-primary" tabindex="0" [ngClass]="['bg-color' | cssClassToAppSpecificColorCssClass, 'border-color' | cssClassToAppSpecificColorCssClass]">
        <div class="icon">
          <ion-icon name="custom-camera" class="text-white"></ion-icon>
        </div>
        <span class="text-white">{{ 'events.edit.field.photo' | translate }}</span>
      </label>
    </div>
    <ng-template #fakeImageInputButton>
      <div class="add-image-btn" [hidden]="readOnly">
        <div class="input-file-trigger">
          <label for="NOTHING" tabindex="0" (click)="annoyUser()">
            <ion-icon name="custom-camera"></ion-icon>
            <br />
            {{ 'events.edit.field.photo' | translate }}
          </label>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>


<ng-template #mobileTemplate>
  <span class="images-container">
    <img
      *ngFor="let attachment of attachments | slice:0:3;"
      [src]="getPictureUrl(attachment) | secureImageSrc | async"
      [class.new-image]="isNewAttachment(attachment)"
      class="thumbnail big mr-2"
    />
    <br>
    <ion-text *ngIf="attachments && attachments.length > 3">+{{attachments.length - 3}}</ion-text>
  </span>
</ng-template>
