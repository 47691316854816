<div *ngIf="!deviceIsMobile" class="custom-field-web" [class.hide-fields]="!isEdited && isEmpty('description')">
  <div [formGroup]="parentFormGroup">
    <textarea (keydown.enter)="$event.stopPropagation()" *ngIf="isEdited; else displayDescriptionTemplate" id="description" formControlName="description"
                [readOnly]="!isEdited" placeholder="{{ 'mobile-placeholder-description' | translate }}">
    </textarea>
  </div>
  
  <ng-template #displayDescriptionTemplate>
    <ng-container *ngIf="event">
      <textarea appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea"
        disabled>{{ event.description }}</textarea>
    </ng-container>
  </ng-template>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" [formGroup]="parentFormGroup" class="custom-field-mobile">
  <ion-textarea
    (keydown.enter)="$event.stopPropagation()"
    formControlName="description"
    rows="8"
    auto-grow = "true"
    [placeholder]="'mobile-placeholder-description' | translate">
  </ion-textarea>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <div *ngIf="event.description" class="notes-section">
    <ion-row>
      <ion-col>
        <label class="label">
          {{customField.title}}
        </label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="pt-0">
        <p>
          <textarea appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea" disabled
          >{{event.description}}</textarea>
        </p>
      </ion-col>
    </ion-row>
  </div>
</div>

