<div *ngIf="!deviceIsMobile" class="custom-field-web" [class.hide-fields]="!isEdited && isEmpty('contractors')">
  <app-select-multiple [parentFormGroup]="parentFormGroup" controlName="contractors" [items]="contractors"
  [isReadOnly]="!isEdited" [isLoading]="!contractorsLoaded"
  placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.contractor.label' | translate }}">
  </app-select-multiple>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item
    class="card-items"
    lines="none"
    (click)="openItemSelectorPopup(contractors, parentFormGroup.value.contractors, 'contractors', 'multiple_basic')">
    <ion-input
      readonly
      [placeholder]="'mobile-placeholder-contractors' | translate"
      *ngIf="parentFormGroup.value.contractors.length === 0">
    </ion-input>
    <ion-icon name="contractor" color="primary" slot="start"></ion-icon>
    <span class="assets-container" *ngIf="parentFormGroup.value.contractors.length > 0">
        {{ parentFormGroup.value.contractors | listToLabelList | join }}
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <ion-row *ngIf="contractors.length > 0">
    <ion-col>
      <header class="label">{{customField.title}}</header>
    </ion-col>
    <ion-col class="contractors">
      <span *ngFor="let contractor of contractors">{{ contractor.name }}</span>
    </ion-col>
  </ion-row>
</div>

