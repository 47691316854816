import { AppAccess } from '@models/space-user';
import Dexie from 'dexie';
import { Space } from '../space';
import { QueueAction, RetryDetails } from '../synchronization/queue-action';
import { ErrorQueueAction } from '../synchronization/error-queue-action';
import { SyncSequence } from '../synchronization/sync-sequence';
import { Notification } from '@models/notification';

const USER_DB_NAME_PREFIX = 'USER';
const USER_DB_VERSION = 1;
const USER_DB_LOGDATA_VERSION = 2;
const USER_DB_CLEAR_LOGDATA_VERSION = 3; // Used in 1.9.33 to clear log data of users
const USER_DB_CUSTOM_EVENT_FORM_VERSION = 4; // Used in 1.9.35 to clear sequence tokens of users
const USER_DB_SITE_TASK_BILLING_VERSION = 5; // Used from 2.0.4 to add app access
const USER_DB_ERROR_ITEMS_VERSION = 6; // Used to add error items and clear sequence token to call the restricted sync api in mobile
const USER_DB_WEB_ONLINE_ONLY_VERSION = 7; // Used to logout the user on update to the web app online only version
const USER_DB_NOTIFICATIONS_VERSION = 8; // Used to add the Notification table
const USER_DB_TASK_NUMBER_VERSION = 9;
const USER_DB_ONBOARDING_VERSION = 10; // Used to set the onboarding status for a user on update

export class UserDatabase extends Dexie {

  public readonly userId: string;

  spaces: Dexie.Table<Space, string>;
  roles: Dexie.Table<string, string>;
  queue: Dexie.Table<QueueAction, string>;
  sequence: Dexie.Table<SyncSequence, [string, string]>;
  logData: Dexie.Table<string, string>;
  errorItemsQueue: Dexie.Table<ErrorQueueAction, string>;
  appAccess: Dexie.Table<AppAccess, string>;
  notifications: Dexie.Table<Notification, string>

  constructor(userId: string, isMobileDevice: boolean) {
    super(USER_DB_NAME_PREFIX + '/' + userId);
    this.userId = userId;

    this.version(USER_DB_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
    });
    this.version(USER_DB_LOGDATA_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '', // Add logData table
    });
    this.version(USER_DB_CLEAR_LOGDATA_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
    }).upgrade((tx) => {
      tx.table('logData').clear();
    });
    this.version(USER_DB_CUSTOM_EVENT_FORM_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
    }).upgrade((tx) => {
      const updateDate = new Date().valueOf();
      if(updateDate > 1663644600000) {
        tx.table('sequence').clear();
      }
    });
    this.version(USER_DB_SITE_TASK_BILLING_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
    }).upgrade((tx) => {
      tx.table('spaces').toCollection().each((space) => {
        let appAccess: AppAccess = {
          siteDiaryAccess: true,
          siteTaskAccess: false,
        }
        tx.table('appAccess').put(appAccess, space.id);
      });
    });
    this.version(USER_DB_ERROR_ITEMS_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
      errorItemsQueue: 'id, spaceId, siteId'
    }).upgrade((tx) => {
      if(isMobileDevice) {
        tx.table('sequence').clear();
        localStorage.setItem('clearedSequenceTokenAfterUpdate', 'true');
      }
      const retryDetails: RetryDetails = {
        retryAttempt: 0,
        lastRetryTime: null,
        shouldRetry: false,
        actionSucceeded: false,
        actionFailed: false
      }
      tx.table('queue').toCollection().modify((queue) => {
        queue.retryDetails = retryDetails;
      });
      tx.table('logData').clear();
    });
    this.version(USER_DB_WEB_ONLINE_ONLY_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
      errorItemsQueue: 'id, spaceId, siteId'
    }).upgrade((tx) => {
      if(!isMobileDevice) {
        localStorage.removeItem('session');
        localStorage.removeItem('userSettings');
      }
    });
    this.version(USER_DB_NOTIFICATIONS_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
      errorItemsQueue: 'id, spaceId, siteId',
      notifications: 'id, spaceId, siteId'
    });
    this.version(USER_DB_TASK_NUMBER_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
      errorItemsQueue: 'id, spaceId, siteId',
      notifications: 'id, spaceId, siteId'
    }).upgrade((tx) => {
      if(isMobileDevice) {
        tx.table('sequence').clear();
      }
    });
    this.version(USER_DB_ONBOARDING_VERSION).stores({
      spaces: 'id',
      roles: '',
      queue: '++id, spaceId',
      sequence: '[spaceId+siteId], spaceId, siteId',
      logData: '',
      appAccess: '',
      errorItemsQueue: 'id, spaceId, siteId',
      notifications: 'id, spaceId, siteId'
    }).upgrade((tx) => {
      localStorage.setItem('updateUserDetailsInSession', 'true');
    });
    this.spaces.mapToClass(Space);
    this.queue.mapToClass(QueueAction);
    this.sequence.mapToClass(SyncSequence);
    this.errorItemsQueue.mapToClass(ErrorQueueAction);
    this.notifications.mapToClass(Notification);
  }
}
