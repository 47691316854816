import { allLegacyFieldTypes, CustomEventField, CustomEventFieldTypeValue, CustomEventLegacyFieldType } from "./custom-event-field";
import { CustomEventFormSection } from "./custom-event-form-layout";
import { ModelElement } from "./model-element";


export class CustomEventForm {
  id: string;
  revision: string;
  title: string;
  isSelected: boolean; // Selected value is used to indicate if the form is selected for the site
  customFields: CustomEventField[];
  formLayout: CustomEventFormSection[];
  siteId: string;

  constructor(
    id: string = ModelElement.generateId(),
    revision: string = '',
    title: string = '',
    isSelected: boolean = true,
    customFields: CustomEventField[] = null,
    formLayout: CustomEventFormSection[] = null,
    siteId: string = '',
  ) {
    this.id = id;
    this.revision = revision;
    this.title = title;
    this.isSelected = isSelected;
    this.customFields = customFields;
    this.formLayout = formLayout;
    this.siteId = siteId;
  }

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend sync
  * @param model Given Event
  */
  public static toModel(json: any, model: CustomEventForm) {
    model.id = json.payload.id;
    model.revision = json.payload.revision;
    model.title = json.payload.title;
    model.isSelected = json.payload.selected;
    model.siteId = json.payload.siteId;
    this.convertDTOtoModel(json, model);
  }

  /**
   * Convert frontend model to backend model
   */
  toDTO() {
    return {
      id: this.id,
      title: this.title,
      configuration: this.convertFormLayoutToDTOConfiguration()
    }
  }

  public static convertSpaceFormDTOToModel(json: any, model: CustomEventForm) {
    model.id = json.id;
    model.revision = json.revision;
    model.title = json.title;
    model.isSelected = json.visible;
    this.convertJSONConfigurationToModel(json, model);
  }

  public get label(): string {
    return this.title;
  }

  convertFormLayoutToDTOConfiguration(): {customFields:CustomEventField[], formLayout: CustomEventFormSection[]} {
    let DTOCustomFields: any = JSON.parse(JSON.stringify(this.customFields));
    DTOCustomFields.forEach((field) => {
      delete field.legacyFieldValue;
      field.fieldType = field.fieldType.value
    });
    let configuration = {
      customFields: DTOCustomFields,
      formLayout: this.formLayout
    }
    return configuration;
  }

  static convertDTOtoModel(json: any, model: CustomEventForm) {
    json.payload.configuration.customFields.forEach((customField) => {
      customField = this.convertDTOFieldToModelField(customField);
    });
    model.customFields = json.payload.configuration.customFields;
    model.formLayout = json.payload.configuration.formLayout;
  }

  static convertJSONConfigurationToModel(json: any, model: CustomEventForm) {
    json.configuration.customFields.forEach((customField) => {
      customField = this.convertDTOFieldToModelField(customField);
    });
    model.customFields = json.configuration.customFields;
    model.formLayout = json.configuration.formLayout;
  }

  static convertDTOFieldToModelField(customField: any): CustomEventField {
    if(customField.fieldType === CustomEventFieldTypeValue.STRING) {
      customField.fieldType = CustomEventField.CUSTOMEVENTFIELDTYPE[0];
    } else if (customField.fieldType === CustomEventFieldTypeValue.NUMBER) {
      customField.fieldType = CustomEventField.CUSTOMEVENTFIELDTYPE[1];
    } else if (customField.fieldType === CustomEventFieldTypeValue.LEGACY) {
      customField.fieldType = CustomEventField.CUSTOMEVENTFIELDTYPE[2];
      const allLegacyFields: CustomEventLegacyFieldType[] = JSON.parse(JSON.stringify(allLegacyFieldTypes));
      const legacyFieldValue = allLegacyFields.find((legacyField) => legacyField.value === customField.id);
      if(legacyFieldValue) {
        customField.legacyFieldValue = legacyFieldValue;
      }
    }
    return customField;
  }
}