<div *ngIf="!deviceService.isMobile">
  <div id="buttons-weather" class="form-input-wrapper">
    <button *ngIf="isOnline" class="btn-set-location option-button selected-option-button"
      (click)="openLocationSettings()" [class.selected-option-button]="showMap" color="primary">
      <em class="fas fa-location-arrow"></em>
      <span>{{ (showMap ? 'app.btn.gps.hide' : 'app.btn.gps.set') | translate }}</span>
    </button>
    <button *ngIf="!isOnline && !noSiteCoordinatesAvailable"
      class="btn-set-location option-button selected-option-button" (click)="applySiteCoordinates()"
      [class.selected-option-button]="!isWeatherIgnoredProp" [disabled]="noSiteCoordinatesAvailable" color="primary">
      <em class="fas fa-location-arrow"></em>
      <span>{{ 'app.btn.gps.offline' | translate }}</span>
    </button>
    <button *ngIf="!isWeatherIgnoredProp" class="btn-ignore-weather ml-2"
      [class.selected-option-button]="isWeatherIgnoredProp" (click)="ignoreWeather()" color="secondary">
      <em class="fas fa-times"></em>
      <span>{{ 'app.btn.ignore' | translate }}</span>
    </button>
  </div>
  <div class="form-input-wrapper" *ngIf="weather && showMap && !readOnly">
    <app-select-gps *ngIf="isOnline" [zoom]="11" [withAutoComplete]="true" [latitude]="weather.lat"
      [longitude]="weather.lng" (coordinatesChange)="setCoordinates($event)">
    </app-select-gps>
  </div>
  <div *ngIf="!isOnline">
    <small *ngIf="!noCoordinatesAvailable" class="text-danger">{{ 'events.edit.weather.offline' | translate }}</small>
    <small *ngIf="noSiteCoordinatesAvailable"
      class="text-danger">{{ 'events.edit.weather.offline-no-site-location' | translate }}</small>
  </div>
</div>

<div *ngIf="deviceService.isMobile">
        <button *ngIf="!isWeatherIgnoredProp" class="btn-ignore-weather ml-2 mobile-form"
        [class.selected-option-button]="isWeatherIgnoredProp" (click)="ignoreWeather()" color="secondary">
        <span>{{ 'app.btn.ignore' | translate }}</span>
      </button>
      <button *ngIf="isOnline" class="btn-set-location option-button selected-option-button mobile-form" (click)="openSelectGpsPopup()"
        [class.selected-option-button]="showMap" color="primary">
        <span>{{ 'app.btn.gps.set' | translate }}</span>
      </button>
      <button *ngIf="!isOnline && !noSiteCoordinatesAvailable" class="btn-set-location option-button selected-option-button mobile-form"
        (click)="applySiteCoordinates()" [class.selected-option-button]="!isWeatherIgnoredProp"
        [disabled]="noSiteCoordinatesAvailable" color="primary">
        <span>{{ 'app.btn.gps.offline' | translate }}</span>
      </button>
</div>
