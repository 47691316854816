<ng-container [formGroup]="parentFormGroup">
  <ng-select [labelForId]="controlName" [formControlName]="controlName"
             [items]="items" bindLabel="name" [isOpen]="isReadOnly ? false : null"
             [multiple]="true" [searchable]="true" [closeOnSelect]="false" [clearOnBackspace]="false" [openOnEnter]="false"
             [loading]="isLoading" [placeholder]="placeholder">
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <ion-badge class="tag-badge" color="primary">{{ item.name }}
        <button class="close-button" ion-button icon-only (click)="clear(item)">
          <ion-icon name="custom-close"></ion-icon>
        </button>
      </ion-badge>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$">
      <div>
        <ion-badge class="tag-badge"  [ngClass]="{'tag-not-selected' : !item$.selected}">{{ item.name }}</ion-badge>
      </div>
    </ng-template>
  </ng-select>
</ng-container>
